// Core
import React, { useState } from "react"

// Redux
import { useAppSelector } from "state-manager/store"

// Images
import closeIcon from "assets/icons/closeWhite.svg"

// Helpers
import clsx from "clsx"

// Styles
import classes from "components/MediaSpendNotification/MediaSpendNotification.module.scss"

const MediaSpendNotification = () => {
  const [isVisible, setIsVisible] = useState(true)

  const { currentMonthlyMediaSpend, maximumMonthlyMediaSpend } = useAppSelector(
    (state) => state.subscriptions.data
  )

  const handleCloseNotification = () => setIsVisible(false)

  const maxMediaSpend = parseFloat(
    (maximumMonthlyMediaSpend || "0").replace(/\s/g, "")
  )
  const currentUsage = parseFloat(
    (currentMonthlyMediaSpend || "0").replace(/\s/g, "")
  )

  const showNotification =
    currentUsage > 0.8 * maxMediaSpend && currentUsage < maxMediaSpend

  if (!maxMediaSpend || !currentUsage || !showNotification || !isVisible) {
    return null
  }

  return (
    <div className={clsx(classes.container)}>
      You are using over 80% of the allowed media spend. Upgrade your
      subscription to increase the limit.
      <span role="button" onClick={handleCloseNotification} data-cy="closeMediaSpendNotification">
        <img src={closeIcon} alt="close" />
      </span>
    </div>
  )
}

export default MediaSpendNotification
