import React from "react"
import { useLocation } from "react-router-dom"
import { FormikProps } from "formik"
import { useAppDispatch, useAppSelector } from "state-manager/store"

// components
import TextInput from "components/ui/TextInput"
import Dropdown from "components/ui/Dropdown"

// actions
import { getAllClients } from "state-manager/actions/clients"

// hooks
import _useDidMount from "hooks/lifecycle/use-did-mount"

import { getAllTeamMembers } from "../../../state-manager/actions/team-members"
import globalSpinner from "../../../utils/global-spinner"
import { BudgetFormValuesType } from ".."

type BudgetOptionType = {
  value: number
  label: string
}

/* TODO functional with clients getting can be removed when clientId will be provided from clientModal through askBudgetModal instead of clientName */
const StepOne: React.FC<FormikProps<BudgetFormValuesType>> = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
}) => {
  const location = useLocation<{ clientName?: string }>()

  const dispatch = useAppDispatch()

  const {
    clients,
    clientsResponseWaiting,
    teamMember,
    teamMembersResponseWaiting,
  } = useAppSelector((state) => ({
    clients: state.allClients.data,
    clientsResponseWaiting: state.allClients.responseWaiting,
    teamMember: state.teamMembers.data,
    teamMembersResponseWaiting: state.teamMembers.responseWaiting,
  }))

  _useDidMount(() => {
    if (!clients.length && !clientsResponseWaiting) {
      dispatch(getAllClients())
    }
    if (!teamMember.length && !teamMembersResponseWaiting) {
      dispatch(getAllTeamMembers())
    }
  })

  if (!clients.length || clientsResponseWaiting) {
    globalSpinner.show()
    return <div>Loading...</div>
  }

  if (location?.state?.clientName) {
    values.clientId = clients
      .filter((item) => item.name === location.state.clientName)
      .reduce((prev, cur) => (prev.id > cur.y ? prev : cur), 0).id
  }

  const budgetOptions: BudgetOptionType[] = teamMember.map((item) => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
  }))
  const clientOptions: BudgetOptionType[] = clients.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  return (
    <>
      <TextInput
        dataCy="budget name"
        label="Budget name"
        name="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMsg={errors.name}
        placeholder="Budget name"
      />
      <br />
      <Dropdown<BudgetOptionType, false>
        multiselect={false}
        dataCy="client name"
        label="Client name"
        options={clientOptions}
        field={{
          name: "clientId",
          value: values.clientId as number,
          onChange: (selectedOption) =>
            selectedOption &&
            setFieldValue("clientId", selectedOption.value, true),
          onBlur: handleBlur,
        }}
        disabled={!!location?.state?.clientName}
        errorMsg={errors.clientId}
        placeholder="Client name"
      />
      <br />
      <Dropdown<BudgetOptionType, false>
        multiselect={false}
        dataCy="budget manager"
        label="Budget Manager"
        options={budgetOptions}
        field={{
          name: "managerId",
          value: values.managerId as number,
          onChange: (selectedOption) =>
            selectedOption &&
            setFieldValue("managerId", selectedOption.value, true),
          onBlur: handleBlur,
        }}
        errorMsg={errors.managerId}
        placeholder="Budget Manager"
      />
    </>
  )
}

export default StepOne
