import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { CLIENT_ACTIONS } from 'state-manager/constants'

export interface IAllClients {
  data: Array<{
    id: number,
    status: string,
    name: string,
    currencyId: number,
    manager: string,
    pacing: number,
    cycleBudget: number,
    rolloverBudget: number,
    budgetTarget: number,
    spent: number,
    labels: Array<number>,
    previousCycleMonth: unknown
  }>,
  responseWaiting: boolean,
  isLoaded: boolean,
  timeLastUpdate: string | null,
}

const initialState: IAllClients = {
  data: [],
  responseWaiting: false,
  isLoaded: false,
  timeLastUpdate: null
}

export const types = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
    currencyId: PropTypes.number,
    manager: PropTypes.string,
    pacing: PropTypes.number,
    cycleBudget: PropTypes.number,
    rolloverBudget: PropTypes.number,
    budgetTarget: PropTypes.number,
    spent: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.number),
  })),
  responseWaiting: PropTypes.bool,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CLIENT_ACTIONS.CLIENT_GET_ALL.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CLIENT_ACTIONS.CLIENT_GET_ALL.SUCCESS:
      return updateObject(state, {
        data: action.data,
        responseWaiting: false,
        isLoaded: true,
        timeLastUpdate: (new Date()).toJSON()
      })

    case CLIENT_ACTIONS.CLIENT_GET_ALL.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    default:
      return state
  }
}
