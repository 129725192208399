import React from 'react'
import clsx from 'clsx'
import { format } from 'date-fns'
import _isEmpty from 'lodash.isempty'
import { Column, FilterFunction, FilterRender } from 'react-table'
import { ColumnFixed } from "react-table-hoc-fixed-columns"

// filters
import SelectFilter, { selectFilterMethod } from 'components/ui/Table/filters/select'
import MultiSelectFilter, { multiSelectFilterMethod } from 'components/ui/Table/filters/multi-select'
import { DatepickerFilter, NewDatePickerFilter, datepickerFilterMethod } from 'components/ui/Table/filters/datepicker'
import TextInputFilter, { textinputFilterMethod } from 'components/ui/Table/filters/TextInput'
import SelectWithSearchFilter, { selectWithSearchFilterMethod } from './filters/select-with-search'
import { cellType } from 'components/ui/Table/index'

// styles
import classes from 'components/ui/Table/Table.module.scss'

// helpers
import { getPacingColorClass } from 'helpers/getPacingColorClass'
import { parseUTCDate } from 'helpers/parseUTCDate'
import getColorClass from '../../../helpers/get-color-class'
import { formatNumber } from 'utils/formatNumber'

// reducers
import { ICustomColumn } from "state-manager/reducers/table"

const DatePickerCell: Column.CellProps['Cell'] = (props) => {
  const dateFormat = props.column.showTime ? "MM.dd.yyyy hh:mm aaaaa'm'" : 'MM.dd.yyyy'
  if (Date.parse(props.value)) {
    const date = parseUTCDate(new Date(props.value))
    return format(date, dateFormat)
  }
  return 'N/A'
}

const getUnicValuesByColumn = (array: Array<Record<string, unknown>>, key: string) => {
  if (key.includes('.')) {
    const splitKey = key.split('.')
    return [...new Set(array.map((item) => item[splitKey[0]][splitKey[1]]))]
  }

  return [...new Set(array.map((item) => item[key]))]
}

const getUnicValuesByColumnArray = (array: Array<Record<string, unknown>>, key: string) => (
  [...new Set(array.map((item) => item[key]).flat())]
)

/* eslint-disable-next-line arrow-body-style */
const getMutuallyExclusiveItems = (
  obj: Array<{ [key: string]: string }>,
  key: string,
  filtered: Array<{ id: string, value: string }>
) => (
  obj.filter((item) => {
    return !filtered.length || filtered.every(({ id: filterKey, value: filterValue }) => {
      return filterKey === key || filterValue === 'all' || item[filterKey] === filterValue
    })
  })
)

type columnTest = Column<unknown> & ColumnFixed<unknown>

export const columnsTransformer = (columns: Array<ICustomColumn>, data: Array<cellType>, filtered: Array<{ id: string, value: string }>) => columns.map((column) => {
  if (typeof column.visible !== 'undefined' && !column.visible) {
    return null
  }

  const newColumn: columnTest = {
    ...column,
    id: column.name,
    accessor: column.accessor ? column.accessor : column.name,
    sortable: !column.isHideSortOption,
    filterable: !column.isHideFilter,
  }

  // @ts-expect-error name from column type
  delete newColumn.name

  switch (column.filterType) {
    case 'select': {
      const selectableFilters = filtered.filter(({ id: filterKey }) => {
        const column = columns.find(({ name }) => name === filterKey)
        return column?.filterType === 'select'
      })

      const excluded = getMutuallyExclusiveItems(data, column.name, selectableFilters)
      newColumn.filterMethod = selectFilterMethod as FilterFunction
      const Filter: FilterRender = ({ onChange, filter }) => SelectFilter({
        name: column.name,
        onChange,
        filter,
        options: column.customOptions ? column.customOptions : getUnicValuesByColumn(excluded, column.name),
      })
      newColumn.Filter = Filter
      break
    }
    case 'selectWithSearch': {
      const filters = filtered.filter(({ id: filterKey }) => {
        const column = columns.find(({ name }) => name === filterKey)
        return column?.filterType && column.filterType === 'select'
      })

      const excludedFilters = getMutuallyExclusiveItems(data, column.name, filters)
      newColumn.filterAll = true
      newColumn.filterMethod = newColumn.filterMethod ? newColumn.filterMethod : selectWithSearchFilterMethod
      newColumn.Filter = newColumn.Filter ? newColumn.Filter : ({ onChange, filter }) => SelectWithSearchFilter({
        onChange,
        filter,
        id: newColumn.id,
        options: getUnicValuesByColumn(excludedFilters, column.name),
      })
      break
    }
    case 'multi-select': {
      newColumn.filterMethod = multiSelectFilterMethod
      newColumn.Filter = ({ onChange, filter }) => MultiSelectFilter({
        onChange,
        filter,
        options: getUnicValuesByColumnArray(data, column.name),
      })
      // eslint-disable-next-line react/display-name
      newColumn.Cell = ({ original }) => {
        const values = original?.labels

        if (!Array.isArray(values)) {
          return 'N/A'
        }

        return (
          <div className="d-flex flex-wrap" style={{ maxHeight: '80px', overflowY: 'auto' }}>
            {values.map((label, index) => (
              <div key={index} className={clsx(classes.multiSelectValue, 'fw-medium fs-sm mr-1 color-white')}>
                {label}
              </div>
            ))}
          </div>
        )
      }
      break
    }
    case 'percentRange': {
      const percentRangeOptions = [
        {
          value: 0,
          label: '0% - 20%',
          min: 0,
          max: 20,
        },
        {
          value: 1,
          label: '20% - 40%',
          min: 20,
          max: 40,
        },
        {
          value: 2,
          label: '40% - 60%',
          min: 40,
          max: 60,
        },
        {
          value: 3,
          label: '60% - 80%',
          min: 60,
          max: 80,
        },
        {
          value: 4,
          label: '80% - 100%',
          min: 80,
          max: 100,
        },
      ]

      const filterMethod: FilterFunction = (filter, row) => selectFilterMethod(filter, row, percentRangeOptions, 'range')
      newColumn.filterMethod = filterMethod
      newColumn.Filter = ({ onChange, filter }) => SelectFilter({
        name: column.name,
        onChange,
        filter,
        options: percentRangeOptions,
        type: 'range',
      })

      // eslint-disable-next-line react/display-name
      newColumn.Cell = ({ value }) => {
        if (column.filterTypeColoredCustom && !_isEmpty(column.filterColoredValues)) {
          return (typeof value === 'string' ?
            value : (
              <span className={getColorClass({
                value,
                min: column.filterColoredValues.min,
                between: column.filterColoredValues.between,
                max: column.filterColoredValues.max,
                isInvertedColors: column.filterColoredValues.isInvertedColors || false,
              })}>
                {formatNumber(Number(value))}{isNaN(value) ? '' : '%'}
              </span>
            ))
        }
        return <span>{value.toFixed(2)}{isNaN(value) ? '' : '%'}</span>
      }
      break
    }
    case 'numberRange':
      const divideIntoEqualParts = (min: number, max: number, parts: number) => {
        if (min < max && max < 1) {
          max = 1
        }
        const result = []
        const delta = (max - min) / (parts - 1)
        while (min < max) {
          result.push(min)
          min += delta
        }
        result.push(max)
        return result
      }

      const unicValuesForNumberRange = getUnicValuesByColumn(data, column.name).filter((item) => !Number.isNaN(item)).sort((a, b) => a - b)

      const min = unicValuesForNumberRange[0]
      const max = unicValuesForNumberRange[unicValuesForNumberRange.length - 1]

      const numberRangeParts = divideIntoEqualParts(min, max, unicValuesForNumberRange.length < 5 ? unicValuesForNumberRange.length : 5).map((item, index) => {
        if (index < 1) {
          return Math.floor(item)
        }
        return Math.ceil(item)
      })

      const numberRangeOptions = numberRangeParts.map((part, index) => {
        const generateOption = (min: number, max: number) => {
          if (typeof min !== 'undefined' && typeof max !== 'undefined' && min < max) {
            return ({
              value: index,
              label: `${min} - ${max}`,
              min,
              max,
            })
          }
        }
        return generateOption(part, numberRangeParts[index + 1])
      }).filter((option) => option)

      const filterMethod: FilterFunction = (filter, row) => selectFilterMethod(filter, row, numberRangeOptions, 'range')
      newColumn.filterMethod = filterMethod
      newColumn.Filter = ({ onChange, filter }) => SelectFilter({
        name: column.name,
        onChange,
        filter,
        options: numberRangeOptions,
        type: 'range',
      })

      if (!column.filterWithoutCell) {
        // eslint-disable-next-line react/display-name
        newColumn.Cell = ({ value }) => {
          if (typeof value !== 'number') {
            return null
          }
          if (column.filterTypeColored) {
            return (typeof value === 'string' ? value : <span className={getPacingColorClass(value)}>{Number(value).toFixed(2)}%</span>)
          }

          return column.integer
            ? Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value.toFixed(2))
            : formatNumber(value)
        }
      }
      break
    case 'datepicker':
      newColumn.filterMethod = datepickerFilterMethod
      newColumn.Filter = ({ onChange, filter }) => DatepickerFilter({
        onChange,
        filter,
        dates: getUnicValuesByColumn(data, column.name),
      })
      newColumn.Cell = DatePickerCell
      break
    case 'new-datepicker':
      newColumn.filterMethod = datepickerFilterMethod
      newColumn.Filter = NewDatePickerFilter
      newColumn.Cell = DatePickerCell
      break
    default: {
      newColumn.filterAll = true
      newColumn.filterMethod = newColumn.filterMethod ? newColumn.filterMethod : textinputFilterMethod
      if (!newColumn.Filter) {
        // eslint-disable-next-line react/display-name
        newColumn.Filter = (({ onChange, filter }) => (
          <TextInputFilter
            onChange={onChange}
            filter={filter}
            dataCy={column.name}
          />
        ))
      }
      break
    }
  }
  // @ts-expect-error filterType from column type
  delete newColumn.filterType

  return newColumn
}).filter((column) => column !== null)
