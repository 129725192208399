import React from "react"
import { Collapse } from "react-collapse"
import DOMPurify from "dompurify"

//hooks
import _useToggle from "hooks/use-toggle"

//styles
import styles from "./CollapsingSubtitle.module.scss"

type propsType = {
  subTitleText: string
  text: string
  isOpenedDefault?: boolean
}

const CollapsingSubtitle: React.FC<propsType> = ({
  subTitleText,
  text,
  isOpenedDefault = false,
}) => {
  const [isOpened, toggleIsOpened] = _useToggle(isOpenedDefault)

  return (
    <div>
      <div onClick={toggleIsOpened} className={styles.subtitleBox}>
        <h3>{subTitleText}</h3>
        <span>{isOpened ? "▲" : "▼"}</span>
      </div>
      <hr />
      <Collapse isOpened={isOpened}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(text, { ADD_ATTR: ["target", "rel"] }),
          }}
        />
      </Collapse>
    </div>
  )
}

export default CollapsingSubtitle
