import React from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'state-manager/store'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// actions
import { getUserData } from 'state-manager/actions/user'
import {
  loadUserData,
  resendEmailVerification,
} from 'state-manager/actions/auth'

// styles
import classes from 'pages/ConfirmEmail/ConfirmEmail.module.scss'

const ConfirmEmail: React.FC = () => {
  const udEmail = useAppSelector((state) => state.userData.email)
  const dispatch = useDispatch()

  const updateUserData = async () => {
    await dispatch(getUserData())
    await dispatch(loadUserData())
  }

  _useDidMount(() => {
    const interval = setInterval(updateUserData, 10000)
    return () => clearInterval(interval)
  })

  return (
    <div className="d-flex justify-content-center align-items-center px-2">
      <div className={clsx(classes.confirmEmailContainer, 'text-center')}>
        <h1 className="fw-regular mt-0">Check Your Inbox</h1>
        <p className="fw-regular">
          To use our platform you should confirm the email address you indicated
          while registering ({udEmail})
        </p>
        <br />
        <p className="fw-regular">
          We sent the email with a confirmation link on this email.
        </p>
        <p className="fw-regular">
          All you have to do is open it and click the link.
        </p>
        <br />
        <p className="fw-regular">
          Didn&apos;t receive an email?
          <button
            type="button"
            data-cy="resend"
            className="transparent-btn fw-regular fs-main"
            onClick={() => dispatch(resendEmailVerification())}
          >
            Resend
          </button>
        </p>
      </div>
    </div>
  )
}

export default ConfirmEmail
