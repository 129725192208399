export const TYPE_MONTHLY = "monthly"
export const TYPE_CUSTOM = "custom"

export const PERIOD_DAYS = "days"
export const PERIOD_WEEKS = "weeks"
export const PERIOD_MONTHS = "months"

export const OVERSPEND_CONTROL_TYPE_PAUSE = "pause"
export const OVERSPEND_CONTROL_TYPE_REDUCE = "reduce"
export const OVERSPEND_CONTROL_TYPE_NONE = "none"

export const OPTIPACER_CONTROL_TYPE_SALES_VALUE = "sales"
export const OPTIPACER_CONTROL_TYPE_CLICKS = "clicks"
export const OPTIPACER_CONTROL_TYPE_IMPRESSIONS = "impressions"
export const OPTIPACER_CONTROL_TYPE_CONVERSIONS = "conversions"
export const OPTIPACER_CONTROL_TYPE_NONE = "none"
