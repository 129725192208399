// @ts-expect-error no d.ts file for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'

// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { BUDGET_ACTIONS } from 'state-manager/constants'

// state-manager
import { AppDispatch, history } from 'state-manager/store'

// routes
import routes from 'routes'

const urlPrefix = 'budget'

export const getAllBudgets = () => (dispatch: AppDispatch) => {
  dispatch({ type: BUDGET_ACTIONS.BUDGET_GET_ALL.ATTEMPT })

  return reqH({
    method: 'GET',
    urlPrefix,
    url: 'table',
  })
    .then((res) => {
      dispatch({
        type: BUDGET_ACTIONS.BUDGET_GET_ALL.SUCCESS,
        data: {
          content: res.data.result,
        },
      })
    })
    .catch(() => {
      dispatch({ type: BUDGET_ACTIONS.BUDGET_GET_ALL.ERROR })
    })
}

export const getAvailableBudget = (data?: Record<string, any>) => (dispatch: AppDispatch) => {
  dispatch({ type: BUDGET_ACTIONS.BUDGET_GET_AVAILABLE_AMOUNT.ATTEMPT })

  reqH({
    method: 'GET',
    urlPrefix,
    url: 'available-amount',
    params: data,
  })
    .then((res) => {
      dispatch({
        type: BUDGET_ACTIONS.BUDGET_GET_AVAILABLE_AMOUNT.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: BUDGET_ACTIONS.BUDGET_GET_AVAILABLE_AMOUNT.ERROR })
    })
}

export const setBudgetFormValues = (values: Record<string, any>, step: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: BUDGET_ACTIONS.BUDGET_SET_FORM_VALUES.SUCCESS,
    data: {
      values,
      step,
    },
  })
}

export const clearFormValues = () => (dispatch: AppDispatch) => {
  dispatch({
    type: BUDGET_ACTIONS.BUDGET_CLEAR_FORM_VALUES.SUCCESS,
  })
}

export const getAllBudgetsChartData = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    urlPrefix,
    url: 'graph',
  })
    .then((res) => {
      dispatch({
        type: BUDGET_ACTIONS.BUDGET_GET_CHART_DATA.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: BUDGET_ACTIONS.BUDGET_GET_CHART_DATA.ERROR })
    })
}

export const getSingleBudget = (id: string) => (dispatch: AppDispatch) => (
  reqH({
    method: 'GET',
    urlPrefix,
    url: id,
  })
    .then((res) => {
      dispatch({
        type: BUDGET_ACTIONS.BUDGET_GET_SINGLE.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: BUDGET_ACTIONS.BUDGET_GET_SINGLE.ERROR })
    })
)

export const getUrlForExportBudgetTable = (format: string, isPrevious: number | boolean) => (dispatch: AppDispatch) => reqH({
  method: 'GET',
  urlPrefix,
  url: `export/${format}`,
  params: {
    previous: +isPrevious,
  },
})
  .then((res) => {
    dispatch({
      type: BUDGET_ACTIONS.BUDGET_EXPORT_TABLE.SUCCESS,
      data: res.data.result,
    })
    return res.data.result
  })
  .catch(() => {
    dispatch({ type: BUDGET_ACTIONS.BUDGET_EXPORT_TABLE.ERROR })
  })

export const getUrlForExportSingleBudget = (id: string, format: string) => (dispatch: AppDispatch) => reqH({
  method: 'GET',
  urlPrefix,
  url: `export/${id}/${format}`,
})
  .then((res) => {
    dispatch({
      type: BUDGET_ACTIONS.BUDGET_EXPORT_SINGLE.SUCCESS,
      data: res.data.result,
    })
    return res.data.result
  })
  .catch(() => {
    dispatch({ type: BUDGET_ACTIONS.BUDGET_EXPORT_SINGLE.ERROR })
  })

export const updateBudget = (data: Record<string, any>, id: string) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: `${id}/update`,
    data,
  })
    .then(() => {
      notification.success('Budget successfully updated!')

      dispatch(getSingleBudget(id))

      dispatch(getAllBudgets())

      history.push({ pathname: formatRoute(routes.budgets, { id }) })
    })
    .finally(() => {
      dispatch({ type: BUDGET_ACTIONS.BUDGET_UPDATE.SYSTEM })
    })
}

export const updateBudgetStatus = (budgetId: number, data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  urlPrefix,
  url: budgetId,
  data,
})
  .then(() => {
    dispatch(getSingleBudget(budgetId))
    dispatch(getAllBudgets())
    notification.success('Budget status successfully updated!')
  })
  .finally(() => {
    dispatch({ type: BUDGET_ACTIONS.BUDGET_UPDATE_STATUS.SYSTEM })
  })

export const addBudget = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    url: urlPrefix,
    data,
  })
    .then(() => {
      notification.success('Budget successfully added!')

      dispatch(getAllBudgets())
      dispatch(clearFormValues())

      history.push(routes.dashboardBudgets)
    })
    .finally(() => {
      dispatch({ type: BUDGET_ACTIONS.BUDGET_ADD.SYSTEM })
    })
}

export const deleteBudget = (id: string) => (dispatch: AppDispatch) => reqH({
  method: 'DELETE',
  urlPrefix,
  url: id,
})
  .then(() => {
    notification.success('Budget successfully deleted!')
    dispatch(getAllBudgets())
  })
