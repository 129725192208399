import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { FormikWizard } from "formik-wizard-form"
import _isEmpty from "lodash.isempty"
import { useAppDispatch, useAppSelector } from "state-manager/store"

// hooks
import _useDidUpdate from "hooks/lifecycle/use-did-update"

// actions
import {
  addBudget,
  updateBudget,
  getSingleBudget,
  getAvailableBudget,
} from "state-manager/actions/budgets"

// constants
import {
  TYPE_MONTHLY,
  OVERSPEND_CONTROL_TYPE_NONE,
  OVERSPEND_CONTROL_TYPE_PAUSE,
  OVERSPEND_CONTROL_TYPE_REDUCE,
  OPTIPACER_CONTROL_TYPE_NONE,
  OPTIPACER_CONTROL_TYPE_SALES_VALUE,
} from "constants/budgets"

import { getSteps } from "pages/ManageBudget/steps"

import FormWrapper from "./FormWrapper"
import EditBudget from "./EditBudget"
import { ISingleBudget } from "state-manager/reducers/single-budget"
import { getDateFromStr, isDateFormat } from "helpers/formatDateStr"

// parse date to YYYY-MM-DD
const parseDate = (date: unknown) => {
  if (date instanceof Date) {
    const year = date.getFullYear()
    let month = `${date.getMonth() + 1}`
    if (month.length === 1) {
      month = "0" + month
    }
    let day = `${date.getDate()}`
    if (day.length === 1) {
      day = "0" + day
    }

    return `${year}-${month}-${day}`
  }
  return ""
}

const parseData = (formValues: BudgetFormValuesType) => {
  const isMonthlyRepeating = formValues.type === TYPE_MONTHLY

  let data: Record<string, any> = {
    name: formValues.name,
    clientId: formValues.clientId,
    managerId: formValues.managerId,
    type: formValues.type,
    amount: formValues.amount,
    campaigns: formValues.campaigns.map(({ id }) => id),
    conversionTarget: formValues.conversionTarget,
    cpcTarget: formValues.cpcTarget,
    roasTarget: formValues.roasTarget,
    budgetRolloverEnabled: formValues.budgetRolloverEnabled,
    rolloverFrom: formValues.budgetRolloverEnabled
      ? formValues.rolloverFrom
      : "",
    overspendControlEnabled: formValues.overspendControlEnabled,
    overspendControlType: formValues.overspendControlEnabled
      ? formValues.overspendControlType
      : OVERSPEND_CONTROL_TYPE_NONE,
    optiPacerControlEnabled: formValues.optiPacerControlEnabled,
    optipacerControlType: formValues.optiPacerControlEnabled
      ? formValues.optipacerControlType
      : OPTIPACER_CONTROL_TYPE_NONE,
    reduceSpendPercents:
      formValues.overspendControlEnabled &&
      formValues.overspendControlType === OVERSPEND_CONTROL_TYPE_REDUCE
        ? formValues.reduceSpendPercents
        : "",
  }

  if (isMonthlyRepeating) {
    data = {
      ...data,
      cycleDate: formValues.cycleDate,
    }
  } else {
    if (formValues.isRepeating) {
      data = {
        ...data,
        isRepeating: formValues.isRepeating,
        startDate: parseDate(formValues.startDate),
        pacingPeriodDuration: formValues.pacingPeriodDuration,
        pacingPeriodType: formValues.pacingPeriodType,
      }
    } else {
      data = {
        ...data,
        isRepeating: formValues.isRepeating,
        startDate: parseDate(formValues.startDate),
        endDate: parseDate(formValues.endDate),
      }
    }
  }

  Object.keys(data).forEach((key) => {
    if (!data[key] && typeof data[key] !== "boolean" && data[key] !== 0) {
      delete data[key]
    }
  })

  return data
}

const getInitialValues = (initialData: ISingleBudget["data"] = {}) => {
  const state = {
    name: initialData.name || "",
    clientId: initialData.client?.id || "",
    managerId: initialData.manager?.id || "",
    type: initialData.type || TYPE_MONTHLY,
    amount: initialData.amount || "",
    cycleDate: initialData.cycleDate || "",
    isRepeating: !!initialData.isRepeating || false,
    startDate: initialData.startDate
      ? new Date(initialData.startDate)
      : new Date(),
    endDate: initialData.endDate ? new Date(initialData.endDate) : new Date(),
    pacingPeriodDuration: initialData.pacingPeriodDuration || "",
    pacingPeriodType: initialData.pacingPeriodType || "days",
    campaigns:
      initialData.campaigns?.map((item) => ({
        account: item.account.name,
        currency: item.currency,
        dailyBudget: item.dailyBudget,
        id: item.id,
        name: item.name,
        source: item.source,
        status: item.status,
        isUseAdSetBudget: item.isUseAdSetBudget,
        fbBuyingType: item.fbBuyingType,
        channelType: item.channelType,
      })) || [],
    conversionTarget: initialData.conversionTarget || "",
    cpcTarget: initialData.cpcTarget || "",
    roasTarget: initialData.roasTarget || "",
    budgetRolloverEnabled: initialData.budgetRolloverEnabled,
    rolloverFrom: initialData.rolloverFrom || "",
    overspendControlEnabled:
      !!(
        initialData.overspendControlEnabled &&
        initialData.overspendControlType !== OVERSPEND_CONTROL_TYPE_NONE
      ) || false,
    overspendControlType:
      initialData.overspendControlType || OVERSPEND_CONTROL_TYPE_PAUSE,
    optiPacerControlEnabled:
      !!(
        initialData.optiPacerControlEnabled &&
        initialData.optipacerControlType !== OPTIPACER_CONTROL_TYPE_NONE
      ) || false,
    optipacerControlType:
      initialData.optipacerControlType || OPTIPACER_CONTROL_TYPE_SALES_VALUE,
    reduceSpendPercents: initialData.reduceSpendPercents || "",
  }

  if (isDateFormat(initialData.startDate)) {
    const { year, month, day } = getDateFromStr(initialData.startDate)
    state.startDate = new Date(year, month - 1, day)
  }
  if (isDateFormat(initialData.endDate)) {
    const { year, month, day } = getDateFromStr(initialData.endDate)
    state.endDate = new Date(year, month - 1, day)
  }

  return state
}

export type BudgetFormValuesType = ReturnType<typeof getInitialValues>

export const AddBudget: React.FC = () => {
  const dispatch = useAppDispatch()

  const { budget, availableAmount } = useAppSelector((state) => ({
    budget: state.singleBudget.data,
    availableAmount: state.manageBudget.availableAmount,
  }))

  _useDidUpdate(() => {
    if (!_isEmpty(budget)) {
      dispatch(
        getAvailableBudget({
          currencyId: budget.client.currency_id,
          budgetId: budget.id,
        })
      )
    }
  }, [budget])

  const steps = useMemo(
    () => getSteps(Math.floor(availableAmount.amount) || 0),
    [availableAmount.amount]
  )

  return (
    <FormikWizard
      steps={steps}
      validateOnNext
      initialValues={getInitialValues()}
      onSubmit={(values) => {
        const data = parseData(values)

        dispatch(addBudget(data))
      }}
    >
      {(props) => <FormWrapper {...props} />}
    </FormikWizard>
  )
}

export const EditExistingBudget: React.FC = () => {
  const params = useParams<{ id: string }>()

  const dispatch = useAppDispatch()

  const { budget, availableAmount } = useAppSelector((state) => ({
    budget: state.singleBudget.data,
    availableAmount: state.manageBudget.availableAmount,
  }))

  useEffect(() => {
    dispatch(getSingleBudget(params.id))
    //eslint-disable-next-line
  }, [])

  _useDidUpdate(() => {
    if (!_isEmpty(budget)) {
      dispatch(
        getAvailableBudget({
          currencyId: budget.client.currency_id,
          budgetId: budget.id,
        })
      )
    }
  }, [budget])

  const steps = useMemo(
    () => getSteps(Math.floor(availableAmount.amount) || 0),
    [availableAmount.amount]
  )

  return (
    <EditBudget
      steps={steps}
      initialValues={getInitialValues(budget)}
      handleSubmit={(values) => {
        const data = parseData(values)
        dispatch(updateBudget(data, params.id))
      }}
    />
  )
}
