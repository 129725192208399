import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { Link, useParams } from "react-router-dom"
import ReactFlagsSelect from "react-flags-select"
import { useAppDispatch, useAppSelector } from "state-manager/store"

// components
import MoneySlider from "pages/Subscriptions/MoneySlider"
import SubscriptionPlanCard from "pages/Subscriptions/SubscriptionPlanCard"
import SpriteIcon from "components/ui/SpriteIcon"
import CustomSubscriptionForm from "pages/Subscriptions/CustomSubscriptionForm"
import ModalCoupon from "pages/Subscriptions/ModalCoupon"
import Coupon from "components/ui/Coupon/Coupon"

// actions
import {
  getAllPlans,
  createPlanRequest,
  getPlanByToken,
} from "state-manager/actions/plans"
import { setCurrentSubscription } from "state-manager/actions/subscriptions"
import { getUserCoupon } from "state-manager/actions/coupon"
import { fetchSubscription } from "state-manager/reducers/subscription"
import { fetchBillingInfo } from "state-manager/reducers/billing-info"

// hooks
import _useToggle from "hooks/use-toggle"

// helpers
import getCurrency from "helpers/get-currency"

// routes
import routes from "routes"

// constants
import { PERIOD_MONTH, PERIOD_YEAR } from "constants/plans"

// styles
import classes from "pages/Subscriptions/Subscriptions.module.scss"

type CustomPlanType = {
  currency: number
  id: string
  monthlyMediaSpend: number
  period: string
  plan: string
  price: number
}

const Subscriptions = () => {
  const {
    coupon,
    allBudgets,
    allPlans,
    configCurrencies,
    currentSubscription,
    billingInfo,
  } = useAppSelector((state) => ({
    coupon: state.coupon,
    allBudgets: state.allBudgets.content,
    allPlans: state.plans.data,
    configCurrencies: state.currencies.data,
    currentSubscription: state.subscriptions.data,
    billingInfo: state.billingInfo.data,
  }))

  const dispatch = useAppDispatch()

  const params = useParams<{ token?: string }>()

  const [customPlanId, setCustomPlanId] = useState<CustomPlanType | null>(null)
  const [isModalOpen, toggleIsModalOpen] = _useToggle()
  const [isCouponModalOpen, toggleCouponModalOpen] = _useToggle()
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState(
    currentSubscription?.plan?.period || ""
  )

  const preselectedPlanCurrencyId = window.localStorage.getItem(
    "preselectedPlanCurrencyId"
  )
  let defaultCurrencyId
  if (currentSubscription?.currency) {
    defaultCurrencyId = currentSubscription?.currency
  } else if (preselectedPlanCurrencyId) {
    defaultCurrencyId = parseInt(preselectedPlanCurrencyId)
  }
  const [currencyId, setCurrencyId] = useState(defaultCurrencyId || 3)

  const [currencyCode, setCurrencyCode] = useState(
    currencyId === 1 ? "US" : "GB"
  )

  const preselectedPlanSliderValue = allPlans.find(
    (item) =>
      item.monthlyMediaSpend ===
      Number(window.localStorage.getItem("preselectedPlanSliderValue"))
  )

  const [sliderValue, setSliderValue] = useState(
    preselectedPlanSliderValue
      ? preselectedPlanSliderValue.monthlyMediaSpend || 25000
      : currentSubscription.plan?.monthlyMediaSpend || 25000
  )

  useEffect(() => {
    if (preselectedPlanSliderValue) {
      window.localStorage.removeItem("preselectedPlanCurrencyId")
      window.localStorage.removeItem("preselectedPlanSliderValue")
    }

    if (!currentSubscription.renewsAt) {
      dispatch(fetchSubscription())
    }
    if (!allPlans.length) {
      dispatch(getAllPlans())
    }
    if (!billingInfo?.cardLastFour) {
      dispatch(fetchBillingInfo())
    }
    dispatch(getUserCoupon()).catch(() => {})
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (params.token && !customPlanId) {
      dispatch(getPlanByToken(params.token)).then((res) => {
        setCustomPlanId(res)
      })
    }
    //eslint-disable-next-line
  }, [])

  if (!allPlans.length) {
    return null
  }

  const currencySymbol =
    configCurrencies?.find((item) => item.id === currencyId)?.symbol || "$"

  const monthly = allPlans.filter((item) => item.period === PERIOD_MONTH)
  const yearly = allPlans.filter((item) => item.period === PERIOD_YEAR)

  const monthlyPlan = allPlans.find(
    (item) =>
      item.monthlyMediaSpend === sliderValue &&
      item.currency === currencyId &&
      item.period === PERIOD_MONTH
  )
  const yearlyPlan = allPlans.find(
    (item) =>
      item.monthlyMediaSpend === sliderValue &&
      item.currency === currencyId &&
      item.period === PERIOD_YEAR
  )

  let allSliderValues = [
    ...new Set(monthly.map((item) => item.monthlyMediaSpend)),
  ]

  const allBudgetsPrice = allBudgets.length
    ? allBudgets
        .map((budget) => budget.cycleBudget)
        .reduce((cur, prev) => cur + prev, 0)
    : null

  // Changing min slider value if allBudgetPrice exist
  if (allBudgetsPrice) {
    allSliderValues = allSliderValues.filter((item) => item > allBudgetsPrice)
  }
  // @ts-expect-error parseInt also accept numbers
  const minSliderValue = parseInt(Math.min(...allSliderValues))
  const maxSliderValue = 4000000

  const isLimitReached = sliderValue === maxSliderValue

  const handleSubscribe = (planId: number, type?: string) => {
    const formData: {
      planId: number
      token?: string
      coupon?: string
    } = {
      planId: planId,
    }

    if (customPlanId) {
      formData.token = params.token
    } else if (coupon?.id) {
      formData.coupon = coupon.id
    }

    if (type) {
      setCurrentSubscriptionType(type)
    }

    if (isLimitReached) {
      toggleIsModalOpen()
      return
    }

    dispatch(setCurrentSubscription(formData))
  }

  if (currentSubscription.hasCustomPlanRequest) {
    return (
      <div
        className={clsx(
          classes.customRequestPendingText,
          "d-flex align-items-center justify-content-center fw-semibold fs-lg color-green"
        )}
      >
        Your request is pending. You will be contacted shortly
      </div>
    )
  }

  const hasUserEnteredBillingInfo = Boolean(billingInfo?.paymentMethods?.length)

  const currency = customPlanId
    ? getCurrency(customPlanId.currency, configCurrencies, true)
    : currencySymbol

  const couponValue = coupon?.percentOff
  const isCouponApplied = Boolean(couponValue)
  const couponMultiplier = couponValue ? (100 - couponValue) / 100 : 1

  // divided by 100 because finalMonthlyPrice is in pennies
  const monthlyPrice = parseInt(`${monthlyPlan ? monthlyPlan.price / 100 : 0}`)
  const yearlyPrice = parseInt(`${yearlyPlan ? yearlyPlan.price / 100 : 0}`)

  const monthlyPriceWithDiscount = parseInt(
    `${monthlyPrice * couponMultiplier}`
  )
  const yearlyPriceWithDiscount = parseInt(`${yearlyPrice * couponMultiplier}`)

  // divided by 100 because finalMonthlyPrice is in pennies and by 4 because we need to calculate 20% discount from original finalMonthlyPrice
  const yearlyProfit = !customPlanId
    ? parseFloat(
        `${
          isCouponApplied
            ? monthlyPrice * 12 - yearlyPriceWithDiscount
            : monthlyPrice * 12 - yearlyPrice
        }`
      )
    : ""

  const yearlyPriceByMonth = parseInt(`${yearlyPrice / 12}`)
  const yearlyPriceWithDiscountByMonth = parseInt(
    `${yearlyPriceWithDiscount / 12}`
  )

  return (
    <div>
      <div className="d-flex justify-content-center mt-10 position-relative">
        {currentSubscription?.renewsAt && (
          <Link to={routes.invoices}>
            <SpriteIcon name="back" size="md" className={classes.closeIcon} />
          </Link>
        )}
        <div className="container text-center">
          <h1 className="fw-regular">Subscriptions</h1>
          <p
            className={clsx(
              classes.descriptionMessage,
              "fw-regular fw-semibold color-black m-auto"
            )}
          >
            Select your typical monthly media spend and see your subscription
            price.
          </p>
          <p
            className={clsx(
              classes.descriptionMessage,
              "fw-regular fw-semibold color-black m-auto mt-2"
            )}
          >
            {hasUserEnteredBillingInfo
              ? "You can upgrade or downgrade your subscription at any time."
              : "Your subscription only starts once the free trial ends, if you add a payment method. It can then be upgraded or downgraded at any time."}
          </p>
          {customPlanId ? (
            <div className="mt-10 d-flex justify-content-center align-items-center">
              <div className="fw-regular fs-18 mr-3">Chosen plan:</div>
              <div className="fw-medium fs-20">
                {customPlanId
                  ? customPlanId.monthlyMediaSpend.toLocaleString()
                  : 0}{" "}
                {getCurrency(customPlanId?.currency, configCurrencies)}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center mt-5 flex-column-sm-up">
              <div className={clsx(classes.slider, "mb-sm-up-8")}>
                <MoneySlider
                  value={sliderValue}
                  min={minSliderValue}
                  max={maxSliderValue}
                  handleSliderChange={setSliderValue}
                  currency={currencySymbol}
                />
              </div>
              <div className="d-flex align-items-center">
                <span className="fw-regular pb-1 color-dark-grey mr-2">
                  Select Currency
                </span>
                <ReactFlagsSelect
                  countries={["GB", "US"]}
                  customLabels={{ GB: "GBP", US: "USD" }}
                  placeholder="GB"
                  onSelect={(countryCode) => {
                    setCurrencyCode(countryCode)
                    setCurrencyId(countryCode === "US" ? 1 : 3)
                  }}
                  selected={currencyCode}
                />
              </div>
            </div>
          )}
          {sliderValue && (
            <div
              className={clsx(
                classes.planCardContainer,
                "d-flex justify-content-between mt-10"
              )}
            >
              {Boolean(monthly.length) && !customPlanId && (
                <div className={clsx(classes.planCard)}>
                  <SubscriptionPlanCard
                    isCouponApplied={isCouponApplied}
                    isFirstSubscription={!currentSubscription?.renewsAt}
                    title="Monthly"
                    currency={currency}
                    isLimitReached={isLimitReached}
                    subscribeOnPlan={(type) => {
                      if (isLimitReached) {
                        toggleIsModalOpen()
                      } else if (monthlyPlan) {
                        handleSubscribe(monthlyPlan.id, type)
                      }
                    }}
                    yearlyPrice={yearlyPrice}
                    yearlyPriceWithDiscount={yearlyPriceWithDiscount}
                    monthlyPrice={monthlyPrice}
                    monthlyPriceWithDiscount={monthlyPriceWithDiscount}
                    yearlyProfit={yearlyProfit}
                    yearlyPriceByMonth={yearlyPriceByMonth}
                    yearlyPriceWithDiscountByMonth={
                      yearlyPriceWithDiscountByMonth
                    }
                  />
                </div>
              )}
              {customPlanId?.period === PERIOD_MONTH && (
                <div className={clsx(classes.planCard)}>
                  <SubscriptionPlanCard
                    isFirstSubscription={!currentSubscription?.renewsAt}
                    title="Monthly"
                    currency={currency}
                    isLimitReached={false}
                    subscribeOnPlan={(type) => {
                      if (isLimitReached) {
                        toggleIsModalOpen()
                      } else if (monthlyPlan) {
                        handleSubscribe(monthlyPlan.id, type)
                      }
                    }}
                    yearlyPrice={0}
                    yearlyPriceWithDiscount={0}
                    monthlyPrice={0}
                    monthlyPriceWithDiscount={customPlanId.price / 100}
                    yearlyProfit={0}
                    yearlyPriceByMonth={0}
                    yearlyPriceWithDiscountByMonth={0}
                  />
                </div>
              )}
              {Boolean(yearly.length) && !customPlanId && (
                <div className={clsx(classes.planCard, classes.planCardFirst)}>
                  <SubscriptionPlanCard
                    isCouponApplied={isCouponApplied}
                    isFirstSubscription={!currentSubscription?.renewsAt}
                    title="Yearly"
                    isYearlyCard={!customPlanId}
                    currency={currency}
                    isLimitReached={isLimitReached}
                    subscribeOnPlan={(type) => {
                      if (isLimitReached) {
                        toggleIsModalOpen()
                      } else if (yearlyPlan) {
                        handleSubscribe(yearlyPlan.id, type)
                      }
                    }}
                    yearlyPrice={yearlyPrice}
                    yearlyPriceWithDiscount={yearlyPriceWithDiscount}
                    monthlyPrice={monthlyPrice}
                    monthlyPriceWithDiscount={monthlyPriceWithDiscount}
                    yearlyProfit={yearlyProfit}
                    yearlyPriceByMonth={yearlyPriceByMonth}
                    yearlyPriceWithDiscountByMonth={
                      yearlyPriceWithDiscountByMonth
                    }
                  />
                </div>
              )}
              {customPlanId?.period === PERIOD_YEAR && (
                <div className={clsx(classes.planCard, classes.planCardFirst)}>
                  <SubscriptionPlanCard
                    isFirstSubscription={!currentSubscription?.renewsAt}
                    title="Yearly"
                    currency={currency}
                    isLimitReached={isLimitReached}
                    subscribeOnPlan={(type) => {
                      if (isLimitReached) {
                        toggleIsModalOpen()
                      } else if (yearlyPlan) {
                        handleSubscribe(yearlyPlan.id, type)
                      }
                    }}
                    yearlyPrice={0}
                    yearlyPriceWithDiscount={0}
                    monthlyPrice={0}
                    monthlyPriceWithDiscount={Number(
                      customPlanId.price / 100 / 12
                    ).toFixed(2)}
                    yearlyProfit={0}
                    yearlyPriceByMonth={0}
                    yearlyPriceWithDiscountByMonth={0}
                  />
                </div>
              )}
            </div>
          )}
          {!customPlanId && (
            <Coupon
              onClick={toggleCouponModalOpen}
              currency={currency}
              monthlyPrice={monthlyPrice}
              monthlyPriceWithDiscount={monthlyPriceWithDiscount}
            />
          )}
        </div>
      </div>
      {isCouponModalOpen && <ModalCoupon onClose={toggleCouponModalOpen} />}
      {isModalOpen && (
        <CustomSubscriptionForm
          currentSubscriptionType={currentSubscriptionType}
          handlePlanRequest={(data) => {
            dispatch(createPlanRequest(data)).then(toggleIsModalOpen)
          }}
          onClose={toggleIsModalOpen}
        />
      )}
    </div>
  )
}

export default Subscriptions
