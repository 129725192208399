import React, { useState } from 'react'
import clsx from 'clsx'
import _isEmpty from 'lodash.isempty'
import { FormikProps } from 'formik'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import Tabs from 'components/ui/Tabs'
import Dropdown from 'components/ui/Dropdown'
import Checkbox from 'components/ui/Checkbox'
import DateInput from 'components/ui/DateInput'
import NumberInput from 'components/ui/NumberInput'

// actions
import { getSingleClient } from 'state-manager/actions/clients'
import { getAvailableBudget } from 'state-manager/actions/budgets'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

// helpers
import getCurrency from 'helpers/get-currency'

// constants
import { formFields, LOCATION_BUDGET_EDIT } from 'pages/ManageBudget/constants'
import { TYPE_MONTHLY, TYPE_CUSTOM } from 'constants/budgets'
import { CHECKBOX_TYPE_SWITCH } from 'components/ui/Checkbox/constants'
import { TAB_SIZE_SM } from 'components/ui/Tabs/constants'

import { BudgetFormValuesType } from '..'

// styles
import classes from 'pages/ManageBudget/steps/styles.module.scss'

type PeriodOptionsType = {
  value: string
  label: string
}
type CycleDateOptionsType = {
  value: number
  label: string
}
const periodOptions: PeriodOptionsType[] = [
  { value: 'days', label: 'days' },
  { value: 'weeks', label: 'weeks' },
  { value: 'months', label: 'months' },
]

const cycleDateItems: Array<null> = new Array(28).fill(null)
const cycleDateOptions: CycleDateOptionsType[] = cycleDateItems.map((_, index) => {
  let ending = 'th'

  if ((index < 10 || index > 19) && (index + 1) % 10 === 1) {
    ending = 'st'
  }

  if ((index < 10 || index > 19) && (index + 1) % 10 === 2) {
    ending = 'nd'
  }

  if ((index < 10 || index > 19) && (index + 1) % 10 === 3) {
    ending = 'rd'
  }

  return {
    value: index + 1,
    label: `${index + 1}${ending}`,
  }
})

const StepTwo: React.FC<FormikProps<BudgetFormValuesType>> = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
}) => {
  const isEditing = window.location.pathname.includes(LOCATION_BUDGET_EDIT)

  const dispatch = useAppDispatch()

  const {
    client,
    clientResponseWaiting,
    availableBudgetResponseWaiting,
    configCurrencies,
  } = useAppSelector((state) => ({
    client: state.singleClient.data,
    clientResponseWaiting: state.singleClient.responseWaiting,
    availableBudgetResponseWaiting: state.manageBudget.responseWaiting,
    configCurrencies: state.currencies.data,
  }))

  _useDidMount(() => {
    const clientId = values.clientId
    if (clientId && !clientResponseWaiting) {
      dispatch(getSingleClient(clientId))
    }
  })

  _useDidUpdate(() => {
    if (!_isEmpty(client) && !isEditing && !availableBudgetResponseWaiting) {
      dispatch(getAvailableBudget({
        // @ts-expect-error no currencyId field in empty object
        currencyId: client.currencyId,
      }))
    }
  }, [client])

  const now = new Date()

  const isRepeating = values.isRepeating

  const [startDate, setStartDate] = useState(values.startDate || now)

  const [endDate, setEndDate] = useState(values.endDate || startDate)

  const minEndDate = new Date(startDate)
  minEndDate.setDate(minEndDate.getDate() + 1)

  if (endDate < minEndDate) {
    setEndDate(minEndDate)
    setFieldValue(formFields.endDate, minEndDate)
  }

  const changeIsRepeating = (status: boolean) => {
    setFieldValue(formFields.isRepeating, status)
    if (status) {
      // @ts-expect-error we can do arithmetic with date
      if (values.startDate - now > 0) {
        setFieldValue(formFields.startDate, now)
        setStartDate(now)
      }
    }
  }

  const changeTab = (tabIndex: number) => {
    const isMonthly = tabIndex === 0
    setFieldValue(formFields.type, isMonthly ? TYPE_MONTHLY : TYPE_CUSTOM)

    // monthly
    if (isMonthly) {
      changeIsRepeating(false)
    }

    // custom
    if (!isMonthly) {
      if (!values.startDate) {
        setFieldValue(formFields.startDate, now)
      }
      if (!values.endDate) {
        setFieldValue(formFields.endDate, now)
      }
    }
  }

  const isMonthly = values.type === TYPE_MONTHLY

  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <Tabs
          size={TAB_SIZE_SM}
          value={isMonthly ? 0 : 1}
          items={[
            { id: 0, label: 'Monthly' },
            { id: 1, label: 'Custom' },
          ]}
          onSelect={changeTab}
        />
      </div>
      <NumberInput
        dataCy="budget amount"
        optional={false}
        label="Budget amount"
        name={formFields.amount}
        value={Number(values.amount)}
        onChange={(val) => setFieldValue(formFields.amount, val, true)}
        onBlur={handleBlur}
        addon={'currencyId' in client ? getCurrency(client?.currencyId, configCurrencies) : undefined}
        errorMsg={errors.amount}
        placeholder="Budget amount"
      />
      <br />
      {isMonthly && (
        <Dropdown<CycleDateOptionsType, false>
          multiselect={false}
          dataCy="monthly budget start date"
          searchable={false}
          label="Monthly budget start date"
          options={cycleDateOptions}
          field={{
            name: formFields.cycleDate,
            value: values.cycleDate as number,
            onChange: (selectedOption) => selectedOption && setFieldValue(formFields.cycleDate, selectedOption.value, true),
            onBlur: handleBlur,
          }}
          errorMsg={errors.cycleDate}
          placeholder="Monthly budget start date"
        />
      )}
      {!isMonthly && (
        <>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <span className={clsx(!isRepeating && 'fw-semibold color-black', isRepeating && 'fw-medium color-dark-grey')}>
              One-off
            </span>
            <Checkbox
              dataCy="repeating"
              type={CHECKBOX_TYPE_SWITCH}
              label="Repeating"
              checked={isRepeating}
              onChange={() => changeIsRepeating(!isRepeating)}
            />
          </div>
          &nbsp;
          <div className="row align-items-start flex-wrap-sm mb-3">
            <div className="w-sm-20 mb-sm-2 mr-1">
              <DateInput
                dataCy="budget start date"
                label="Budget start date"
                selected={startDate}
                onChange={(date) => {
                  if (!date) {
                    return
                  }
                  setFieldValue(formFields.startDate, new Date(date))
                  setStartDate(date)

                  if (endDate < date) {
                    setFieldValue(formFields.endDate, new Date(date))
                    setEndDate(date)
                  }
                }}
                maxDate={isRepeating ? now : undefined}
              />
              <div className="text-right fw-medium fs-sm color-red mt-1">{errors.startDate as string}</div>
            </div>
            {isRepeating ? (
              <>
                <div style={{ minWidth: 150 }}>
                  <NumberInput
                    dataCy="budget pacing period"
                    className={classes.budgetPacingPeriod}
                    optional={false}
                    label="Budget pacing period"
                    name={formFields.pacingPeriodDuration}
                    value={Number(values.pacingPeriodDuration)}
                    onChange={(val) => setFieldValue(formFields.pacingPeriodDuration, val, true)}
                    onBlur={handleBlur}
                    errorMsg={errors.pacingPeriodDuration}
                    placeholder="Budget pacing period"
                  />
                </div>
                <div style={{ minWidth: 100, marginLeft: 8 }}>
                  <Dropdown<PeriodOptionsType, false>
                    multiselect={false}
                    hiddenLabel
                    dataCy="period type"
                    label="Period type"
                    searchable={false}
                    options={periodOptions}
                    field={{
                      name: formFields.pacingPeriodType,
                      value: values.pacingPeriodType || 'days',
                      onChange: (selectedOption) => selectedOption && setFieldValue(formFields.pacingPeriodType, selectedOption.value),
                      onBlur: handleBlur,
                    }}
                    placeholder="Pacing"
                  />
                </div>
              </>
            ) : (
              <div className="w-sm-20">
                <DateInput
                  dataCy="budget end date"
                  errorMsg={errors.endDate as string}
                  label="Budget end date"
                  minDate={minEndDate}
                  selected={endDate}
                  onChange={(date) => {
                    if (!date) {
                      return
                    }
                    setFieldValue(formFields.endDate, new Date(date))
                    setEndDate(date)
                  }}
                />
              </div>
            )}
          </div>
          {!isRepeating && (
            <div className="row align-items-start flex-wrap-sm">
              Your campaigns will be automatically enabled when the budget starts and paused when it ends
            </div>
          )}
        </>
      )}
    </>
  )
}

export default StepTwo
