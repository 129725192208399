import React from "react"
import {
  ITooltip,
  Tooltip as ReactTooltip,
  TooltipRefProps,
} from "react-tooltip"
import styles from "./style.module.scss"

const Tooltip = (props: ITooltip & React.RefAttributes<TooltipRefProps>) => {
  return <ReactTooltip className={styles.tooltip} {...props} />
}

export default Tooltip
