import React from "react"
import clsx from "clsx"
import { FormikProps } from "formik"

//hooks
import _useToggle from "hooks/use-toggle"

// components
import Checkbox from "components/ui/Checkbox"
import NumberInput from "components/ui/NumberInput"
import RadioButton from "components/ui/RadioButton"
import Tooltip from "components/ui/Tooltip"

// constants
import {
  OVERSPEND_CONTROL_TYPE_PAUSE,
  OVERSPEND_CONTROL_TYPE_REDUCE,
  OPTIPACER_CONTROL_TYPE_CLICKS,
  OPTIPACER_CONTROL_TYPE_CONVERSIONS,
  OPTIPACER_CONTROL_TYPE_IMPRESSIONS,
  OPTIPACER_CONTROL_TYPE_SALES_VALUE,
} from "constants/budgets"
import { CHECKBOX_TYPE_SWITCH } from "components/ui/Checkbox/constants"
import { LOCATION_BUDGET_EDIT } from "../constants"

// styles
import classes from "pages/ManageBudget/steps/styles.module.scss"
import { BudgetFormValuesType } from ".."
import ModalTermsAndConditions from "pages/shared/modals/ModalTermsAndConditions"

const StepFive: React.FC<FormikProps<BudgetFormValuesType>> = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
}) => {

  const isEditing = window.location.pathname.includes(LOCATION_BUDGET_EDIT)

  const [isTermsModalOpen, toggleIsTermsModalOpen] = _useToggle(false)

  //budget rollover
  const isBudgetRolloverEnabled = !!values.budgetRolloverEnabled

  const toggleBudgetRolloverEnabled = () => {
    setFieldValue("budgetRolloverEnabled", !isBudgetRolloverEnabled)
  }

  //overspend
  const isOverspendControlEnabled = values.overspendControlEnabled
  const overspendControlPauseEnabled =
    values.overspendControlType === OVERSPEND_CONTROL_TYPE_PAUSE
  const overspendControlReduceEnabled =
    values.overspendControlType === OVERSPEND_CONTROL_TYPE_REDUCE

  const toggleOverspendControlEnabled = () => {
    setFieldValue("overspendControlEnabled", !isOverspendControlEnabled)
  }

  const switchToOverspendReduce = () => {
    setFieldValue("overspendControlType", OVERSPEND_CONTROL_TYPE_REDUCE)
  }

  const switchToOverspendPause = () => {
    setFieldValue("overspendControlType", OVERSPEND_CONTROL_TYPE_PAUSE)
  }

  //optipacer
  const isOptiPacerEnabled = values.optiPacerControlEnabled
  const optipacerControlSalesValueEnabled =
    values.optipacerControlType === OPTIPACER_CONTROL_TYPE_SALES_VALUE
  const optipacerControlClicksEnabled =
    values.optipacerControlType === OPTIPACER_CONTROL_TYPE_CLICKS
  const optipacerControlImpressionsEnabled =
    values.optipacerControlType === OPTIPACER_CONTROL_TYPE_IMPRESSIONS
  const optipacerControlConversionsEnabled =
    values.optipacerControlType === OPTIPACER_CONTROL_TYPE_CONVERSIONS

  const toggleOptiPacerEnabled = () => {
    setFieldValue("optiPacerControlEnabled", !isOptiPacerEnabled)
  }

  const switchToSalesValue = () => {
    setFieldValue("optipacerControlType", OPTIPACER_CONTROL_TYPE_SALES_VALUE)
  }

  const switchToClicks = () => {
    setFieldValue("optipacerControlType", OPTIPACER_CONTROL_TYPE_CLICKS)
  }

  const switchToImpressions = () => {
    setFieldValue("optipacerControlType", OPTIPACER_CONTROL_TYPE_IMPRESSIONS)
  }

  const switchToConverions = () => {
    setFieldValue("optipacerControlType", OPTIPACER_CONTROL_TYPE_CONVERSIONS)
  }

  const handleToggleOverspendControl = () => {
    if (isOptiPacerEnabled) {
      toggleOverspendControlEnabled()
      toggleOptiPacerEnabled()
      return
    }
    toggleOverspendControlEnabled()
  }

  const handleEnableOptiPacer = () => {
    setFieldValue("overspendControlEnabled", true)
    toggleOptiPacerEnabled()
    toggleIsTermsModalOpen()
  }

  const isOptiPacerDisabled =
    isEditing &&
    values.campaigns.some(
      (campaign) =>
        campaign.source === "Facebook Ads" &&
        (campaign.isUseAdSetBudget || campaign.fbBuyingType === "RESERVED")
    )

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center my-2">
        <span className="fw-medium fs-20">Budget rollover</span>
        <Checkbox
          type={CHECKBOX_TYPE_SWITCH}
          checked={isBudgetRolloverEnabled}
          onChange={toggleBudgetRolloverEnabled}
          dataCy="budget rollover"
        />
      </div>
      {isBudgetRolloverEnabled && (
        <NumberInput
          dataCy="starting rollover"
          label="Starting rollover (for the first cycle)"
          name={"rolloverFrom"}
          value={values.rolloverFrom}
          onChange={(val) => setFieldValue("rolloverFrom", val)}
          onBlur={handleBlur}
          errorMsg={errors.rolloverFrom}
          placeholder="Starting rollover (for the first cycle)"
        />
      )}
      <div className="d-flex justify-content-between align-items-center my-2">
        <span className="fw-medium fs-20">Overspend control</span>
        <Checkbox
          dataCy="overspend control"
          type={CHECKBOX_TYPE_SWITCH}
          checked={isOverspendControlEnabled}
          onChange={handleToggleOverspendControl}
        />
      </div>
      {isOverspendControlEnabled && (
        <>
          <div className="fw-regular color-dark-grey">
            Overspend control pauses or reduces daily spends on your campaigns
            if the budget is depleted during the cycle.
          </div>
          <div className={classes.checkbox_container}>
            <div className="d-flex align-items-center mt-2">
              <RadioButton
                dataCy="overspend pause"
                name="radio-button-overspend-pause"
                checked={overspendControlPauseEnabled}
                onChange={switchToOverspendPause}
              />
              <span className="fw-medium color-black fs-16 ml-1">Pause</span>
            </div>
            <div className="d-flex align-items-start mt-2 align-items-center flex-wrap justify-content-start">
              <div
                className={clsx(
                  classes.overspendControlReduceWrapper,
                  "d-flex align-items-center align-self-start flex-grow-sm"
                )}
              >
                <RadioButton
                  dataCy="overspend reduce spend"
                  name="radio-button-overspend-reduce-spend"
                  checked={overspendControlReduceEnabled}
                  onChange={switchToOverspendReduce}
                />
                <span className="fw-medium color-black fs-16 ml-1 mr-4">
                  Reduce spend
                </span>
              </div>
            </div>
            {overspendControlReduceEnabled && (
              <>
                <div className="position-relative">
                  <div
                    className={clsx(
                      classes.overspendControlReduceInput,
                      "rc-input-number input"
                    )}
                  >
                    <input
                      autoComplete="off"
                      step="1"
                      maxLength={3}
                      type="number"
                      data-cy="overspend control reduce input"
                      name="reduceSpendPercents"
                      className="rc-input-number-input _ar_hide_"
                      value={values.reduceSpendPercents}
                      onChange={(event) => {
                        if (event.target.value.length > 3) {
                          return
                        }
                        setFieldValue(
                          "reduceSpendPercents",
                          event.target.value,
                          true
                        )
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="fw-regular input-addon">
                    <span className={classes.overspendControlReduceInputAddon}>
                      %
                    </span>
                  </div>
                </div>

                <div className={clsx("fw-medium fs-sm color-red")}>
                  {errors.reduceSpendPercents}
                </div>
              </>
            )}
          </div>
          {overspendControlReduceEnabled && (
            <p className="fw-regular text-left pl-5 color-dark-grey mt-2">
              If campaign budgets are automatically reduced they will need to be
              increased again manually when needed as this won’t happen
              automatically when the next budget cycle starts.
            </p>
          )}
        </>
      )}
      <div
        style={{
          marginTop: "45px",
          position: "relative",
        }}
        className={classes.border_container}
      >
        <div className="d-flex justify-content-between align-items-center my-2">
          <span className="fw-medium fs-20">OptiPacer budget manager</span>
          {isOptiPacerDisabled && (
            <Tooltip
              content={`Cannot enable OptiPacer. Budget contains campaigns with lifetime budgets or API limitations.`}
              id={`tooltip-optipacer-checkbox`}
            />
          )}

          <div data-tooltip-id={`tooltip-optipacer-checkbox`}>
            <Checkbox
              dataCy="overspend control"
              type={CHECKBOX_TYPE_SWITCH}
              checked={isOptiPacerEnabled}
              onChange={() => {
                if (isOptiPacerEnabled) toggleOptiPacerEnabled()
                else toggleIsTermsModalOpen()
              }}
              disabled={isOptiPacerDisabled}
            />
          </div>
        </div>
        {isOptiPacerEnabled && (
          <>
            <div className="fw-regular color-dark-grey">
              OptiPacer automatically adjusts campaign daily budgets based on
              campaign performance and your target budget amount for a cycle.
              Spend your target budget effectively and on time, every time.
            </div>
            <div className="mt-2" style={{ fontWeight: "bold" }}>
              Performance metric
            </div>
            <div className={classes.checkbox_container}>
              <div className="d-flex align-items-center mt-2">
                <RadioButton
                  dataCy="optipacer sales value"
                  name="radio-button-optipacer-sales-value"
                  checked={optipacerControlSalesValueEnabled}
                  onChange={switchToSalesValue}
                />
                <span className="fw-medium color-black fs-16 ml-1">
                  Sales Value
                </span>
              </div>
              <div className="d-flex align-items-center mt-2">
                <RadioButton
                  dataCy="optipacer clicks"
                  name="radio-button-optipacer-clicks"
                  checked={optipacerControlClicksEnabled}
                  onChange={switchToClicks}
                />
                <span className="fw-medium color-black fs-16 ml-1">
                  Clicks
                </span>
              </div>
              <div className="d-flex align-items-center mt-2">
                <RadioButton
                  dataCy="optipacer impressions"
                  name="radio-button-optipacer-impressions"
                  checked={optipacerControlImpressionsEnabled}
                  onChange={switchToImpressions}
                />
                <span className="fw-medium color-black fs-16 ml-1">
                  Impressions
                </span>
              </div>
              <div className="d-flex align-items-center mt-2">
                <RadioButton
                  dataCy="optipacer converions"
                  name="radio-button-optipacer-conversions"
                  checked={optipacerControlConversionsEnabled}
                  onChange={switchToConverions}
                />
                <span className="fw-medium color-black fs-16 ml-1">
                  Conversions
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      {isTermsModalOpen && (
        <ModalTermsAndConditions
          onClose={toggleIsTermsModalOpen}
          enableOptiPacer={handleEnableOptiPacer}
        />
      )}
    </div>
  )
}

export default StepFive
