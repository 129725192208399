import React, { useMemo, useState } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'

import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import DataTable from 'pages/TeamMembers/ModalEditTeamMember/table'
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'
import Dropdown from 'components/ui/Dropdown'

// actions
import { getAllClients } from 'state-manager/actions/clients'
import { getAllTeamMembers, updateTeamMemberClients } from 'state-manager/actions/team-members'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

// helpers
import getCurrency from 'helpers/get-currency'

// constants
import { MODAL_SIZE_LG } from 'components/ui/Modal/constants'

import { IAllClients } from 'state-manager/reducers/all-clients'

// styles
import classes from 'pages/TeamMembers/ModalEditTeamMember/modalEditTeamMember.module.scss'

type ModalEditTeamMemberType = {
  id: number | string
  onClose: () => void
}

const ModalEditTeamMember: React.FC<ModalEditTeamMemberType> = ({
  id,
  onClose,
}) => {
  const [clients, setClients] = useState<IAllClients['data']>([])
  const [availableClients, setAvailableClients] = useState<IAllClients['data']>([])

  const { 
    teamMembers,
    allClients,
    configCurrencies,
  } = useAppSelector((state) => ({
    teamMembers: state.teamMembers.data,
    allClients: state.allClients.data,
    configCurrencies: state.currencies.data,
  }))

  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      'newClient': 0,
    },

    onSubmit: (values, { resetForm }) => {
      if (!values.newClient) {
        return
      }
      const client = allClients.find((item) => item.id === values.newClient)

      setClients((prevState) => prevState.concat(client))

      resetForm()
    },
  })

  const teamMember = useMemo(() => teamMembers.find((teamMember) => teamMember.id === id), [id, teamMembers])

  _useDidMount(() => {
    if (!teamMembers.length) {
      dispatch(getAllTeamMembers())
    }

    if (!allClients.length) {
      dispatch(getAllClients())
    }

    if (teamMember) {
      setClients(allClients.filter((client) => teamMember.clients.includes(client.id)))
      setAvailableClients(allClients.filter((client) => !teamMember.clients.includes(client.id)))
    }
  })

  _useDidUpdate(() => {
    setAvailableClients(allClients.filter((client) => !clients.find((item) => item.id === client.id)))
  }, [clients])

  const DropdownOptions = availableClients.map((client) => {
    const clientBudget = client.budgetSummary
      ? `${getCurrency(client.currencyId, configCurrencies)} - ${getCurrency(client.currencyId, configCurrencies, true)}${client.budgetSummary}`
      : <span className={classes.textOpacity}>No budgets</span>

    return {
      label: (
        <>
          <span>{client.name}</span>
          <span>{clientBudget}</span>
        </>
      ),
      value: client.id,
    }
  })

  const handleClientDelete = (clientId: number) => {
    setClients((prevState) => prevState.filter((item) => item.id !== clientId))
  }

  const handleClientUpdate = () => {
    const clientIds = clients.map((client) => client.id)

    const data = {
      clients: clientIds,
    }

    dispatch(updateTeamMemberClients(id, data))
    onClose()
  }

  return (
    <Modal
      stretchHeight
      size={MODAL_SIZE_LG}
      withCloseButton
      title={`${teamMember?.firstName} ${teamMember?.lastName} Clients`}
      onClose={onClose}>
      <>
        <div className={clsx(classes.tableContainer, 'mb-2')}>
          <DataTable
            data={clients}
            deleteClient={handleClientDelete}
            currencies={configCurrencies}
          />
        </div>
        <div className="d-flex justify-content-around mb-2 flex-column-sm-up align-items-center-sm-up">
          <span className="w-20 mb-sm-up-2">
            <Dropdown
              menuListMinimumHeight
              customOptions={({ label }) => (
                <div className="w-20 d-flex justify-content-between">
                  <span>{label.props.children[0]}</span>
                  <span className="pr-2">{label.props.children[1]}</span>
                </div>
              )}
              options={DropdownOptions}
              field={{
                name: 'newClient',
                value: formik.values.newClient,
                onChange: (selectedOption) => formik.setFieldValue('newClient', selectedOption.value),
                onBlur: formik.handleBlur,
              }}
            />
          </span>
          <Button
            title="Add"
            className={clsx(classes.submitButton, 'ml-3')}
            // @ts-expect-error it works fine with formik.handleSubmit but if needed there are a solution to add form as wrapper
            onClick={formik.handleSubmit}
          />
        </div>
        <Button
          dataCy='save team member changes'
          type="submit"
          title="Save Changes"
          onClick={handleClientUpdate}
        />
      </>
    </Modal>
  )
}

export default ModalEditTeamMember
