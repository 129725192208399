import PropTypes from "prop-types"

/* helpers */
import updateObject from "state-manager/reducers/helpers/update-object"

/* constants */
import { CAMPAIGN_ACTIONS } from "state-manager/constants"

export interface ICampaigns {
  data: Array<{
    id: number
    name: string
    pacing: number
    suggetedDailyBudget: number
    spentToDate: number
    spentYesterday: number
    spentSameDayLastWeek: number
    dailyBudget: number
  }>
  dataSimple: {
    data: Array<{
      id: number
      source: string
      status: string
      account: string
      name: string
      currencyId: number
      dailyBudget: number
      channelType: string
    }>
    dataSourceList: Array<string>
    currentPage: number
    lastPage: number
    statusList: Array<string>
  }
}

const initialState: ICampaigns = {
  data: [],
  dataSimple: {
    data: [],
    dataSourceList: [],
    currentPage: 1,
    lastPage: 1,
    statusList: [],
  },
}

export const dataSimpleTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      source: PropTypes.string,
      status: PropTypes.string,
      account: PropTypes.string,
      name: PropTypes.string,
      currencyId: PropTypes.number,
      dailyBudget: PropTypes.number,
    })
  ),
  pagination: PropTypes.shape({
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    last: PropTypes.bool,
    first: PropTypes.bool,
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    numberOfElements: PropTypes.number,
  }),
}

export const types = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      pacing: PropTypes.number,
      suggetedDailyBudget: PropTypes.number,
      spentToDate: PropTypes.number,
      spentYesterday: PropTypes.number,
      spentSameDayLastWeek: PropTypes.number,
      dailyBudget: PropTypes.number,
    })
  ),
  dataSimple: PropTypes.shape({
    content: dataSimpleTypes.content,
    pagination: dataSimpleTypes.pagination,
  }),
  // dataSimple: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number,
  //   source: PropTypes.string,
  //   status: PropTypes.string,
  //   // account: PropTypes.object,
  //   name: PropTypes.string,
  // })),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL.SUCCESS:
      return updateObject(state, {
        data: action.data,
      })

    case CAMPAIGN_ACTIONS.CAMPAIGN_GET_ALL_SIMPLE.SUCCESS: {
      const {
        data,
        current_page: currentPage,
        last_page: lastPage,
        status_list: statusList,
        data_source_list: dataSourceList,
        channelType: channelType,
      } = action.data
      return {
        data: state.data,
        dataSimple: {
          data,
          dataSourceList,
          currentPage,
          lastPage,
          statusList,
          channelType,
        },
      }
    }
    case CAMPAIGN_ACTIONS.CAMPAIGN_SEARCH.SUCCESS:
      return updateObject(state, {
        dataSimple: action.data,
      })

    default:
      return state
  }
}
