import React from 'react'
import { useFormikContext } from 'formik'
import clsx from 'clsx'
import { Line } from 'rc-progress'
import { useDispatch } from 'react-redux'

import ManageBudgetPageLayout from 'pages/ManageBudget/layout'

import { setBudgetFormValues } from 'state-manager/actions/budgets'

import classes from 'pages/ManageBudget/styles.module.scss'

import { getSteps } from 'pages/ManageBudget/steps'

import { BudgetFormValuesType } from '..'

const steps = 5
const subtitles = getSteps(0).map((item) => item.subtitle)

type FormWrapperType = {
  currentStepIndex: number;
  isLastStep: boolean;
  isPrevDisabled: boolean;
  handlePrev: () => void;
  handleNext: () => void;
  renderComponent: () => JSX.Element;
}

const FormWrapper: React.FC<FormWrapperType> = ({
  currentStepIndex: originalCurrentStep,
  isLastStep,
  isPrevDisabled,
  handlePrev,
  handleNext,
  renderComponent,
}) => {
  const currentStep = originalCurrentStep + 1
  const { values: currentStepValues } = useFormikContext<BudgetFormValuesType>()

  const dispatch = useDispatch()

  const getNextButtonText = () => {
    switch (true) {
      case isLastStep:
        return 'Add Budget'
      case currentStep === 4 && !(currentStepValues.conversionTarget || currentStepValues.cpcTarget || currentStepValues.roasTarget):
        return 'Skip'
      default:
        return 'Next Step'
    }
  }

  const handleSubmitForm = () => {
    handleNext()
    dispatch(setBudgetFormValues(currentStepValues, currentStep - 1))
  }

  return (
    <ManageBudgetPageLayout title="Add Budget" subtitles={subtitles} step={currentStep}>
      <div className={classes.formWrapper}>
        <div className={clsx('pt-7 mb-8', classes.formInner, currentStep !== 3 && classes.formInnerMaxWidth)}>
          {renderComponent()}

          <div className={clsx(classes.buttonsBarWrapper, 'p-2')}>
            <div className="d-flex align-items-center justify-content-between mw-920 m-auto">
              {currentStep !== 1 && (
                <button
                  data-cy="go back"
                  type="button"
                  className="btn color-green-fade"
                  onClick={handlePrev}
                  disabled={isPrevDisabled}>
                  Back
                </button>
              )}

              <div className="d-flex align-items-center justify-content-center">
                <Line
                  percent={currentStep * (100 / steps)}
                  strokeWidth={4}
                  trailWidth={4}
                  strokeColor="#3DB14A"
                  style={{ width: 200 }}
                  className="d-none-sm"
                />
                <div className={clsx(classes.progressBarSm, 'color-dark-grey fw-regular ml-3')}>
                  Step {currentStep}&nbsp;of&nbsp;{steps}
                </div>
              </div>

              <button data-cy={getNextButtonText()} className="btn color-green" type="button" onClick={handleSubmitForm}>
                {getNextButtonText()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ManageBudgetPageLayout>
  )
}

export default FormWrapper
