import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { TEAMMEMBER_ACTIONS } from 'state-manager/constants'
import {
  ROLE_READ_ONLY_USER,
  ROLE_MANAGER,
  ROLE_COMPANY_ADMIN,
  ROLE_SUPER_COMPANY_ADMIN,
} from 'constants/roles'

export type teamMemberType = {
  id: number
  clients: Array<number>
  companyId: number
  countryId: number
  email: string
  firstName: string
  lastName: string
  role:
    | typeof ROLE_MANAGER
    | typeof ROLE_COMPANY_ADMIN
    | typeof ROLE_READ_ONLY_USER
    | typeof ROLE_SUPER_COMPANY_ADMIN
  roleLabel:
    | 'Manager'
    | 'Company admin'
    | 'Read only user'
    | 'Super company admin'
  status: 'unconfirmed' | 'complete' | 'invited'
  zipCode: string
  phoneNumber: string
  preferences: string
  hasVerifiedEmail: 0 | 1
  avatar: {
    id: number
    userId: number
    type: string
    hash: string
  }
}

export interface ITeamMember {
  data: Array<teamMemberType>
  requests: Array<teamMemberType>
  responseWaiting: boolean
  isLoaded: boolean
}

const initialState: ITeamMember = {
  data: [],
  requests: [],
  responseWaiting: false,
  isLoaded: false,
}

export const types = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      clients: PropTypes.arrayOf(PropTypes.number),
      companyId: PropTypes.number,
      countryId: PropTypes.number,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      role: PropTypes.oneOf([
        ROLE_MANAGER,
        ROLE_COMPANY_ADMIN,
        ROLE_READ_ONLY_USER,
        ROLE_SUPER_COMPANY_ADMIN,
      ]),
      roleLabel: PropTypes.oneOf([
        'Manager',
        'Company admin',
        'Read only user',
        'Super company admin',
      ]),
      status: PropTypes.oneOf(['unconfirmed', 'complete', 'invited']),
      zipCode: PropTypes.string,
      phoneNumber: PropTypes.string,
      preferences: PropTypes.string,
      hasVerifiedEmail: PropTypes.oneOf([0, 1]),
      avatar: PropTypes.shape({
        id: PropTypes.number,
        userId: PropTypes.number,
        type: PropTypes.string,
        hash: PropTypes.string,
      }),
    })
  ),
  responseWaiting: PropTypes.bool,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL.SUCCESS:
      return updateObject(state, {
        data: action.data,
        responseWaiting: false,
        isLoaded: true,
      })

    case TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL.ERROR:
      return updateObject(state, {
        responseWaiting: false,
        isLoaded: true,
      })

    case TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL_REQUESTS.SUCCESS:
      return updateObject(state, {
        requests: action.data.reverse(),
      })

    default:
      return state
  }
}
