// Core
import React, { PropsWithChildren, useState } from 'react'

// components
import Sidebar from 'components/Sidebar'
import Header from 'components/Header'
import TrialNotification from 'components/TrialNotification'
import MediaSpendNotification from 'components/MediaSpendNotification'
import ExceededMaxMediaSpendNotification from 'components/ExceededMaxMediaSpendNotification'

// Redux
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// Constants
import { DEMO_MODAL } from 'state-manager/constants'

// Pages
import PageTemplate from 'pages/shared/PageTemplate'
import ModalAddClient from 'pages/shared/modals/ModalClient'
import ModalAddTeamMember from 'pages/shared/modals/ModalAddTeamMember'
import ModalDemoWarning from 'pages/shared/modals/ModalDemoWarning'

const AuthorizedPageLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const isShowDemoModal = useAppSelector((state) => state.demoModal.isVisible)
  const { isVisible: isClientModalVisible, id: clientID } = useAppSelector((state) => state.clientModal)
  const { isVisible: isAddTeamMemberModalVisible } = useAppSelector((state) => state.addTeamMemberModal)

  const dispatch = useAppDispatch()

  const handleCloseDemoModal = () => {
    dispatch({ type: DEMO_MODAL.HIDE })
  }

  const reverseMenuOpen = (shouldClose?: any) => {
    if (shouldClose === 'shouldClose') {
      setMenuOpen(false)
    } else {
      setMenuOpen(!isMenuOpen)
    }
  }

  return (
    <>
      <MediaSpendNotification/>
      <ExceededMaxMediaSpendNotification/>
      <TrialNotification />
      <div className="d-flex">
        <Sidebar isOpen={isMenuOpen} onClick={reverseMenuOpen} />
        <div className="page-content">
          <Header onClick={reverseMenuOpen} />
          <PageTemplate>
            {children}
          </PageTemplate>
        </div>

        {isClientModalVisible && <ModalAddClient clientId={clientID} />}
        {isAddTeamMemberModalVisible && <ModalAddTeamMember />}
        {isShowDemoModal && (
          <ModalDemoWarning onClose={handleCloseDemoModal} />
        )}
      </div>
    </>
  )
}

export default AuthorizedPageLayout
