import React, { SyntheticEvent, useRef } from 'react'
import clsx from 'clsx'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

// components
import InputWrapper from 'components/ui/InputWrapper'
import SpriteIcon from 'components/ui/SpriteIcon'

// styles
import 'react-datepicker/dist/react-datepicker.css'

type props = {
  label?: string;
  optional?: boolean;
  errorMsg?: string;
  selected?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: ReactDatePickerProps['onChange'];
  dataCy: string;
}

const DateInput: React.FC<props> = ({
  label = '',
  optional = false,
  errorMsg = '',
  selected = new Date(),
  minDate = null,
  maxDate,
  onChange = () => {},
  dataCy,
}) => {
  const dateRef = useRef<DatePicker>(null)

  const openDatePicker = () => {
    dateRef.current?.setOpen(true)
  }

  const handleChangeRaw = (e: SyntheticEvent) => {
    e.preventDefault()
  }

  return (
    <InputWrapper
      label={label}
      optional={optional}
      dataCy={dataCy}
      errorMsg={errorMsg}>
      <DatePicker
        className={clsx(
          'input',
          errorMsg && 'input-invalid',
        )}
        selected={selected}
        onChange={onChange}
        onChangeRaw={handleChangeRaw}
        ref={dateRef}
        minDate={minDate}
        maxDate={maxDate}
      />
      <div
        role="button"
        className="input-icon"
        onClick={openDatePicker}>
        <SpriteIcon name="datepicker" size="sm" />
      </div>
    </InputWrapper>
  )
}

export default DateInput
