export default [
  {
    subTitleText: "Introduction",
    text: `OptiPacer automatically adjusts campaign daily budgets based on campaign performance and your target budget amount for a cycle. It helps you spend your target budget effectively and on time. By enabling OptiPacer, you agree to the following terms and conditions.`,
    isOpenedDefault: true,
  },
  {
    subTitleText: "Notice",
    text: `OptiPacer may take up to 24 hours to make any necessary changes to your campaign budgets after it is enabled.`,
    isOpenedDefault: true,
  },
  {
    subTitleText: "1. Acceptance of Terms",
    text: `By enabling the OptiPacer feature, you agree to these terms and conditions, which are in addition to the overall <a href="https://edee.ai/termsofservice" target="_blank" rel="noopener noreferrer">EDEE Terms of Service</a>.`,
  },
  {
    subTitleText: "2. Feature Description",
    text: `OptiPacer is a budget adjustment tool that:
    Works at the budget level, allowing it to be used alongside bid strategies.
    Analyzes and ranks campaign performance to allocate budgets efficiently.
    Helps users control their budgets more effectively.`,
  },
  {
    subTitleText: "3. User Eligibility",
    text: `Only users with write access to the budget (Super Company Admin, Admin, Manager) can enable or make changes to OptiPacer.`,
  },
  {
    subTitleText: "4. Budget Adjustments",
    text: `OptiPacer will:
    Analyze campaign data from the last 30 and 7 days to determine budget adjustments.
    Make changes to campaign budgets in a waterfall manner, prioritizing the best performing campaigns for increases, and the and worst performing campaigns for decreases.
    Perform capacity checks to ensure that increased budgets can be efficiently utilized.`,
  },
  {
    subTitleText: "5. Data and Performance Analysis",
    text: `OptiPacer will compare performance metrics such as ROAS, cost per conversion, CPC, or CPM to determine significant differences.
    It will apply a weighting system to recent performance data to inform budget adjustments.
    If campaigns don’t have sufficient data for your chosen Performance Metric, OptiPacer will analyze and use the next available Performance Metric instead, by order of importance, until there is sufficient data. For example, if your chosen Performance Metric is ‘Sales Value’ but there is no data for this, OptiPacer will utilize ‘Conversions’ data instead if available, until there is sufficient data for ‘Sales Value’. If no ‘Conversions’ data is available, OptiPacer will utilize ‘Clicks’ data instead, and so on.
    Users will receive notifications and daily summaries of OptiPacer activities and changes.`,
  },
  {
    subTitleText: "6. Excluded Campaigns",
    text: `Certain campaign types are excluded from using OptiPacer:
    Meta Ads campaigns with Lifetime budgets.
    Meta Ads campaigns with the Reservation buying type.
    Any campaigns with API limitations regarding budget changes.`,
  },
  {
    subTitleText: "7. Historical Data Requirement",
    text: `OptiPacer can be used without historical data. In such cases, it will make no changes until sufficient data is collected.`,
  },
  {
    subTitleText: "8. Notifications and Logs",
    text: `Users will receive email and in-app notifications if budgets are under pacing but unable to spend more efficiently.
    A detailed changelog will be maintained, documenting all budget adjustments made by OptiPacer.`,
  },
  {
    subTitleText: "9. Overspend Control",
    text: `The Overspend Control feature will be automatically enabled when OptiPacer is active and cannot be disabled.`,
  },
  {
    subTitleText: "10. Frequency of Changes",
    text: `OptiPacer will check data daily and make changes at most every 48 hours.`,
  },
  {
    subTitleText: "11. Termination",
    text: `You can disable the OptiPacer feature at any time through the EDEE budget interface. Disabling OptiPacer will stop any further automatic adjustments to your campaign budgets.`,
  },
  {
    subTitleText: "12. Limitation of Liability",
    text: `EDEE will not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the OptiPacer feature, even if EDEE has been advised of the possibility of such damages.`,
  },
  {
    subTitleText: "13. Modifications to Terms",
    text: `EDEE reserves the right to modify these terms and conditions at any time. Any changes will be posted on our app and/or website, and your continued use of the OptiPacer feature constitutes acceptance of the updated terms.
    By enabling the OptiPacer feature, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.`,
  },
]
