import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import queryString from 'query-string'

// components
import Modal from 'components/ui/Modal'
import TextInput from 'components/ui/TextInput'
import PasswordInput from 'components/ui/PasswordInput'
import Checkbox from 'components/ui/Checkbox'
import Button from 'components/ui/Button'
import TelInput from 'components/ui/TelInput'

// actions
import { signUp, invitationSignUp } from 'state-manager/actions/auth'

// helpers
import { hasError } from 'helpers/field-has-errors'
import { getNameValidation, getPasswordValidation } from 'utils/validation'

// constants
import { formFields } from 'pages/SignUp/constants'
import { PASSWORD } from 'constants/regex'

// routes
import routes from 'routes'
import { useAppDispatch, useAppSelector } from '../../state-manager/store'

const SignUp = () => {
  const location = useLocation()
  const history = useHistory()

  const dispatch = useAppDispatch()

  const configCountries = useAppSelector((state) => state.countries.data)

  const locationParams = location.search ? queryString.parse(location.search) : null

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: locationParams ? locationParams.c : '',
      email: locationParams ? locationParams.email : '',
      phoneNumber: '',
      password: '',
      countryId: '',
      terms: false,
      confirmPassword: '',
    },

    validationSchema: Yup.object({
      firstName: getNameValidation('First name'),
      lastName: getNameValidation('Last name'),
      companyName: Yup
        .string()
        .label('Company name')
        .required(),
      phoneNumber: Yup
        .string()
        .label('Phone number')
        .required(),
      email: Yup
        .string()
        .label('Email')
        .email()
        .required(),
      terms: Yup
        .bool()
        .label('Terms')
        .required(),
      password: getPasswordValidation('Password'),
      confirmPassword: Yup
        .string()
        .label('Confirm password')
        .matches(PASSWORD, 'Password invalid')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required(),
    }),

    onSubmit: (values) => {
      const { firstName, lastName, email, countryId, password, terms, companyName, phoneNumber } = values
      const cId = countryId === undefined ? 0 : configCountries.find((item) => item.dial_code === `+${countryId}`)?.id
      const pNumber = phoneNumber?.substring((cId?.toString().length || 0))
      const formData = {
        firstName,
        lastName,
        email,
        password,
        terms,
        countryId: cId,
        companyName,
        phoneNumber: pNumber,
      }

      if (locationParams?.code) {
        dispatch(invitationSignUp({
          ...formData,
          code: locationParams.code
        }))
      } else {
        dispatch(signUp(formData))
      }
    },
  })

  const fistNameHasError = hasError('firstName', formik.touched, formik.errors)
  const lastNameHasError = hasError('lastName', formik.touched, formik.errors)
  const companyNameHasError = hasError('companyName', formik.touched, formik.errors)
  const emailHasError = hasError('email', formik.touched, formik.errors)
  const phoneHasError = hasError('phoneNumber', formik.touched, formik.errors)
  const passwordHasError = hasError('password', formik.touched, formik.errors)
  const confirmPasswordHasError = hasError('confirmPassword', formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty || !formik.values.terms

  return (
    <Modal
      title="Sign Up"
      withCloseButton={false}
      onClose={() => history.push(routes.modalSignIn[0])}
      isScrollbarHidden={false}>
      <div className="form-page">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <div className="row flex-column-sm">
              <div className="w-20 mb-sm-2">
                <TextInput
                  label="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  errorMsg={fistNameHasError && formik.errors.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="First Name"
                  dataCy="firstName"
                />
              </div>
              <div className="divider" />
              <div className="w-20">
                <TextInput
                  label="Last Name"
                  name="lastName"
                  value={formik.values.lastName}
                  errorMsg={lastNameHasError && formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Last Name"
                  dataCy="lastName"
                />
              </div>
            </div>
            <br />
            <div className="row flex-column-sm">
              <div className="w-20 mb-sm-2">
                <TelInput
                  country="gb"
                  label="Phone Number"
                  errorMsg={phoneHasError && formik.errors.phoneNumber}
                  name={formFields.phoneNumber}
                  value={formik.values.phoneNumber}
                  setFieldValue={formik.setFieldValue}
                  onBlur={formik.handleBlur}
                  dataCy="phone"
                />
              </div>
              <div className="divider" />
              <div className="w-20">
                <TextInput
                  disabled={Boolean(locationParams && locationParams.c)}
                  label="Company Name"
                  name="companyName"
                  value={formik.values.companyName}
                  errorMsg={companyNameHasError && formik.errors.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Company Name"
                  dataCy="companyName"
                />
              </div>
            </div>
            <br />
            <TextInput
              disabled={Boolean(locationParams && locationParams.email)}
              label="Email"
              name="email"
              value={formik.values.email}
              errorMsg={emailHasError && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Email"
              dataCy="email"
            />
            <br />
            <PasswordInput
              label="Password"
              name="password"
              value={formik.values.password}
              errorMsg={passwordHasError && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Password"
              dataCy="password"
            />
            <br />
            <PasswordInput
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm Password"
              value={formik.values.confirmPassword}
              errorMsg={confirmPasswordHasError && formik.errors.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dataCy="repeatPassword"
            />
            <br />
            <div className="d-flex align-items-center">
              <Checkbox
                name={formFields.terms}
                checked={formik.values.terms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                dataCy="agree"
              />
              <div className="fw-medium">I agree to <a href="https://edee.ai/termsofservice" rel="noreferrer" target="_blank" data-cy="terms">Terms of Service</a> and <a href="https://edee.ai/privacy" rel="noreferrer" target="_blank" data-cy="privacy">Privacy Policy</a></div>
            </div>
            <br />
          </div>
          <Button
            type="submit"
            disabled={isSubmitDisabled}
            title="Continue"
            dataCy="submit"
          />
          <br />
        </form>
        <p className="text-center fw-regular">
          Already have an account?{' '}
          <Link to={routes.modalSignIn[0]} className="fw-semibold" data-cy="login">
            Login
          </Link>
        </p>
      </div>
    </Modal>
  )
}

export default SignUp
