import React, { FC, SVGProps } from "react"

// components
import Modal from "components/ui/Modal"
import Button from "components/ui/Button"
import CollapsingSubtitle from "components/ui/CollapsingSubtitle"

//consts
import termsAndConditionsTexts from "./termsAndConditionsTexts"

//styles
import styles from "./styles.module.scss"

type propsType = {
  yes?: string
  no?: string
  title?: string
  size?: "sm" | "md" | "lg" | "xl"
  icon?: string | FC<SVGProps<SVGSVGElement>>
  description?: string
  type?: "warning"
  handleClick?: () => void
  onClose?: () => void
  onNoClick?: () => void
  enableOptiPacer: () => void
}

const ModalTermsAndConditions: React.FC<propsType> = ({
  size = "xl",
  onClose,
  enableOptiPacer,
}) => (
  <Modal
    size={size}
    withCloseButton
    onClose={onClose}
    closeButtonEnabled={false}
    isScrollbarHidden={true}
  >
    <div className={styles.scroll}>
      <h2>OptiPacer Terms & Conditions</h2>
      <div>
        {termsAndConditionsTexts.map((textEl, idx) => {
          const { subTitleText, text, isOpenedDefault } = textEl
          return (
            <CollapsingSubtitle
              key={idx}
              subTitleText={subTitleText}
              text={text}
              isOpenedDefault={isOpenedDefault}
            />
          )
        })}
      </div>
    </div>
    <div className={styles.buttonBox}>
      <Button
        dataCy="modal terms and conditions not now button"
        title="Not now"
        style={{
          background: "transparent",
          color: "green",
        }}
        onClick={onClose}
      />
      <Button
        dataCy="modal terms and conditions enable button"
        title="Enable"
        onClick={enableOptiPacer}
      />
    </div>
  </Modal>
)

export default ModalTermsAndConditions
