import React, { useState } from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom"

// components
import PaymentMethods from "pages/Invoices/PaymentMethods"
import InvoicesThumbnail from "pages/Invoices/InvoicesThumbnail"
import DataTable from "pages/Invoices/table"
import Modal from "components/ui/Modal"
import Button from "components/ui/Button"

// actions
import { getAllInvoices } from "state-manager/actions/invoices"
import { useAppDispatch, useAppSelector } from "state-manager/store"

// hooks
import _useDidMount from "hooks/lifecycle/use-did-mount"

// routes
import routes from "routes"

import WarningIcon from "assets/icons/warning.svg"

// styles
import classes from "pages/Invoices/Invoices.module.scss"

import { _useGlobalDemo } from "hooks/use-demo"
import { checkIfUserHasntDefaultPaymentMethod } from "routes/access/authorized-access"

const Invoices = () => {
  const demoHOC = _useGlobalDemo()

  const history = useHistory()

  const { invoices, billingInfo, canManageSubscriptions, currentSubscription } =
    useAppSelector((state) => ({
      invoices: state.invoices.data,
      billingInfo: state.billingInfo.data,
      canManageSubscriptions: state.userData.permissions.canManageSubscriptions,
      currentSubscription: state.subscriptions.data,
    }))

  const dispatch = useAppDispatch()

  const [isModalVisible, setIsModalVisible] = useState(
    checkIfUserHasntDefaultPaymentMethod(
      Boolean(billingInfo?.defaultPaymentMethod),
      Boolean(billingInfo?.paymentMethods?.length),
      canManageSubscriptions,
      currentSubscription.status === "active"
    )
  )

  const isTrialEnded = !currentSubscription.isOnTrial
  const isSubscriptionCanceled = currentSubscription.isSubscriptionCanceled

  _useDidMount(() => {
    dispatch(getAllInvoices())
  })

  const handleAddPayment = () => {
    history.push(routes.billing)
  }

  const handleCloseModal = () => setIsModalVisible(false)

  return (
    <>
      <InvoicesThumbnail
        isSubscriptionCanceled={isSubscriptionCanceled}
        isTrialEnded={isTrialEnded}
      />
      <div
        className={clsx(
          classes.invoicesContainer,
          "d-flex justify-content-between"
        )}
      >
        <div className={classes.tableContainer}>
          <DataTable data={invoices} />
        </div>
        <div className={classes.paymentMethodContainer}>
          <div
            className={clsx(
              classes.paymentMethodHeader,
              "row align-items-center"
            )}
          >
            <div className="fw-semibold color-black fs-lg">Payment Methods</div>
            <div
              className={clsx(
                classes.plusButton,
                "color-green fs-lg text-center",
                "cursor-pointer"
              )}
              onClick={demoHOC(handleAddPayment)}
            >
              +
            </div>
          </div>
          <PaymentMethods data={billingInfo} />
        </div>
      </div>
      {isModalVisible && (
        <Modal withCloseButton onClose={handleCloseModal}>
          <img className="align-center" src={WarningIcon} alt="icon" />
          <h3 className="text-center fw-regular fs-lg">
            You have not chosen a default payment method
          </h3>
          <p className="text-center fw-regular">
            Please choose a default payment method to ensure uninterrupted
            platform access and avoid subscription issues.
          </p>
          <p className="text-center fw-regular pt-2 pb-2">
            If you believe you are receiving this in error or cannot set a
            default payment method please contact our support team{" "}
            <a href="mailto:hello@edee.ai">hello@edee.ai</a>
          </p>
          <Button
            type="submit"
            title="Got it"
            className="background-red mt-2"
            onClick={handleCloseModal}
            dataCy="close_payment_warning"
          />
        </Modal>
      )}
    </>
  )
}

export default Invoices
