import React, { useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { FormikProps } from "formik"
import uniqBy from "lodash.uniqby"
import { useAppDispatch, useAppSelector } from "state-manager/store"

// components
import Table, { filter } from "components/ui/Table"

// actions
import { getAllCampaignsSimple } from "state-manager/actions/campaigns"

// hooks
import _useDidMount from "hooks/lifecycle/use-did-mount"

// constants
import { formFields, LOCATION_BUDGET_EDIT } from "pages/ManageBudget/constants"
import { ACTIVE } from "constants/status"

// proptypes
import { delayAction } from "utils/delayAction"
import { BudgetFormValuesType } from ".."

//utils
import notification from "utils/notifications"

type campaignType = {
  id: number
  source: string
  status: string
  account: string
  name: string
  currencyId: number
  dailyBudget: number
  channelType: string
}

const DELAY = 2000

const StepThree: React.FC<FormikProps<BudgetFormValuesType>> = ({
  values,
  setFieldValue,
  errors,
}) => {
  const isEditing = window.location.pathname.includes(LOCATION_BUDGET_EDIT)
  const params = useParams<{ id?: string }>()

  const [filters, setFilters] = useState<Array<filter>>([])
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const timer = useRef<NodeJS.Timeout>()

  const {
    data: campaigns,
    dataSourceList,
    lastPage,
    statusList,
    currentPage,
  } = useAppSelector((state) => state.campaigns.dataSimple)

  const dispatch = useAppDispatch()

  _useDidMount(() => {
    getAllCampaignsSimple(
      dispatch,
      1,
      itemsPerPage,
      isEditing ? params.id : undefined
    )
  })

  const selected = values.campaigns
  const pagination = {
    currentPage,
    totalPages: lastPage,
  }

  const columns = useMemo(
    () => [
      {
        name: "source",
        Header: "Data source",
        filterType: "select",
        customOptions: dataSourceList,
        width: 150,
        isHideSortOption: true,
      },
      {
        name: "account",
        Header: "Account",
        filterType: "selectWithSearch",
        width: 250,
        style: {
          whiteSpace: "unset",
          wordBreak: "break-all",
          textAlign: "center",
        },
        isHideSortOption: true,
      },
      {
        name: "status",
        Header: "Status",
        filterType: "select",
        customOptions: statusList,
        width: 120,
        Cell: ({ value }) => (
          <span
            className={`text-capitalize color-${
              value.toLowerCase() === ACTIVE ? "green" : "red"
            }`}
          >
            {value.toLowerCase()}
          </span>
        ),
        isHideSortOption: true,
      },
      {
        name: "name",
        Header: "Campaign name",
        style: { whiteSpace: "unset", wordBreak: "break-all" },
        isHideSortOption: true,
      },
    ],
    [dataSourceList, statusList]
  )

  const data = useMemo(
    () => uniqBy([...selected, ...campaigns], "id"),
    [selected, campaigns]
  )

  return (
    <>
      <div className="mb-3" style={{ color: "#F77D7D" }}>
        {data.some(
          (item) => item.source === "Google Ads" && item.channelType === "VIDEO"
        ) && (
          <p>
            Video campaigns in this budget cannot be paused in EDEE due to
            Google Ads API restrictions. Overspend Control will not work for
            these campaigns.
          </p>
        )}
      </div>
      <div className="mb-2 text-center fw-medium fs-sm color-red">
        {errors.campaigns}
      </div>

      <Table
        selectable
        onToggleSelection={(item: campaignType) => {
          let newValue = [...selected]

          const idx = newValue.indexOf(item)

          // check to see if the key exists
          if (idx !== -1) {
            // it does exist so we will remove it using destructing
            newValue = [...newValue.slice(0, idx), ...newValue.slice(idx + 1)]
          } else {
            newValue.push(item)

            const containsVideoType =
              item.source === "Google Ads" && item.channelType === "VIDEO"

            if (containsVideoType) {
              notification.error(
                "This budget contains one or multiple Video campaigns. The Google Ads API does not allow status changes for this campaign type hence Overspend Control will not be able to pause these campaigns. Changes to all other campaign types will function normally"
              )
            }
          }

          setFieldValue(formFields.campaigns, newValue)
        }}
        onSelectAll={(isSelected) => {
          if (isSelected) {
            setFieldValue(
              formFields.campaigns,
              uniqBy([...selected, ...campaigns], "id")
            )
          } else {
            setFieldValue(formFields.campaigns, [])
          }
        }}
        selected={selected.map(({ id }) => id)}
        columns={columns}
        data={data}
        sortable={false}
        selectedCount
        pageSize={itemsPerPage}
        manual
        isServerSidePagination
        pagination={pagination}
        className="budgetTable"
        onServerSidePagination={({ nextPage }) => {
          getAllCampaignsSimple(
            dispatch,
            nextPage + 1,
            itemsPerPage,
            isEditing ? params.id : undefined,
            filters
          )
        }}
        onServerSidePageSizeChange={(itemsPerPage) => {
          setItemsPerPage(itemsPerPage)
          getAllCampaignsSimple(
            dispatch,
            1,
            itemsPerPage,
            isEditing ? params.id : undefined,
            filters
          )
        }}
        onFilter={(filters) => {
          setFilters(filters)

          delayAction(
            () => {
              getAllCampaignsSimple(
                dispatch,
                1,
                itemsPerPage,
                isEditing ? params.id : undefined,
                filters
              )
            },
            DELAY,
            timer
          )
        }}
      />
    </>
  )
}

export default StepThree
