// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { TEAMMEMBER_ACTIONS } from 'state-manager/constants'

// state-manager
import { AppDispatch, history } from 'state-manager/store'

// routes
import routes from 'routes'

const urlPrefix = 'team-member'

export const getAllTeamMembers = () => (dispatch: AppDispatch) => {
  dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL.ATTEMPT })

  return reqH({
    method: 'GET',
    url: urlPrefix,
    withoutLoader: true,
  })
    .then((res) => {
      dispatch({
        type: TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL.ERROR })
    })
}

export const getAllTeamMembersRequests = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    urlPrefix,
    url: 'requests',
    withoutLoader: true,
  })
    .then((res) => {
      dispatch({
        type: TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL_REQUESTS.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_GET_ALL.ERROR })
    })
}

export const updateTeamMemberClients = (id: string | number, data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: `${id}/clients/update`,
    data,
  })
    .then(() => {
      notification.success('Team member successfully updated!')
      dispatch(getAllTeamMembers())
      history.push(routes.teamMembers)
    })
    // TODO Check if errors
    .finally(() => {
      dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_UPDATE_CLIENTS.SYSTEM })
    })
}

export const updateTeamMemberRole = (id: string, data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: `${id}/role/update`,
    data,
  })
    .then(() => {
      notification.success('Team member role successfully updated!')
      dispatch(getAllTeamMembers())
    })
    .finally(() => {
      dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_UPDATE_ROLE.SYSTEM })
    })
}

export const inviteTeamMember = (data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  url: 'invitation/request-team-member-access',
  data,
})
  .then(() => {
    dispatch(getAllTeamMembersRequests())
  })
  .finally(() => {
    dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_INVITE.SYSTEM })
  })

export const deleteTeamMember = (id: string | number) => (dispatch: AppDispatch) => reqH({
  method: 'DELETE',
  urlPrefix,
  url: id.toString(),
})
  .then(() => {
    notification.success('Team member successfully deleted!')
    dispatch(getAllTeamMembers())
  })
  .finally(() => {
    dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_DELETE.SYSTEM })
  })

export const resendInviteTeamMember = (companyId: string, inviteId: string) => (dispatch: AppDispatch) => reqH({
  method: 'GET',
  url: `invitation/request-team-member-access-resent/${companyId}/${inviteId}`,
})
  .then(() => {
    notification.success('Re-send successful!')
  })
  .finally(() => {
    dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_RESEND.SYSTEM })
  })

export const deleteInviteTeamMember = (companyId: string, inviteId: string) => (dispatch: AppDispatch) => reqH({
  method: 'DELETE',
  url: `invitation/company/${companyId}/${inviteId}`,
})
  .then(() => {
    notification.success('Invite successfully deleted!')
    dispatch(getAllTeamMembersRequests())
  })
  .finally(() => {
    dispatch({ type: TEAMMEMBER_ACTIONS.TEAMMEMBER_DELETE_INVITE.SYSTEM })
  })
