import React from "react"
import clsx from "clsx"
import { Input } from "reactstrap"

//components
import Tooltip from "../Tooltip"

// constants
import {
  CHECKBOX_TYPE_DEFAULT,
  CHECKBOX_TYPE_SWITCH,
} from "components/ui/Checkbox/constants"

// styles
import classes from "components/ui/Checkbox/Checkbox.module.scss"

type props = {
  id?: string
  name?: string
  label?: string
  dataCy?: string
  type?: typeof CHECKBOX_TYPE_DEFAULT | typeof CHECKBOX_TYPE_SWITCH
  checked?: boolean
  disabled?: boolean
  disabledTooltipText?: string
  onChange?: () => void
  onBlur?: () => void
  className?: string
}

const Checkbox: React.FC<props> = ({
  id,
  name,
  label,
  type = CHECKBOX_TYPE_DEFAULT,
  checked,
  disabled,
  disabledTooltipText,
  onChange,
  onBlur,
  className,
  dataCy,
}) => (
  <div>
    {disabled && disabledTooltipText && (
      <Tooltip
        content={disabledTooltipText}
        id={`tooltip-table-optipacer-checkbox-${id}`}
      />
    )}
    <div
      className={clsx(className, "d-flex align-items-center")}
      data-tooltip-id={`tooltip-table-optipacer-checkbox-${id}`}
    >
      <label
        className={clsx(
          label && classes.checkboxLabel,
          disabled && classes.checkboxLabelDisabled,
          "fw-medium d-flex align-items-center"
        )}
      >
        <Input
          name={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          className={clsx(
            classes.checkboxInput,
            disabled && classes.disabled_checkbox,
            type === CHECKBOX_TYPE_SWITCH && classes.checkboxSwitch
          )}
          data-cy={dataCy}
        />
        {label && (
          <span
            className={clsx(
              checked && "fw-semibold color-black",
              !checked && "fw-medium color-dark-grey"
            )}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  </div>
)

export default Checkbox
