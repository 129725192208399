import * as Yup from 'yup'

// components
import StepOne from 'pages/ManageBudget/steps/StepOne'
import StepTwo from 'pages/ManageBudget/steps/StepTwo'
import StepThree from 'pages/ManageBudget/steps/StepThree'
import StepFour from 'pages/ManageBudget/steps/StepFour'
import StepFive from 'pages/ManageBudget/steps/StepFive'

// constants
import {
  TYPE_MONTHLY,
  OVERSPEND_CONTROL_TYPE_REDUCE,
} from 'constants/budgets'
import { formFields } from 'pages/ManageBudget/constants'

export const getSteps = (maxBudget: number) => [
  {
    id: '1',
    component: StepOne,
    subtitle: '',
    validationSchema: Yup.object()
      .shape({
        name: Yup.string()
          .label('Name')
          .required(),
        clientId: Yup.string()
          .label('Client')
          .required(),
      }),
  },
  {
    id: '2',
    component: StepTwo,
    subtitle: 'Budget amount and time period',
    validationSchema: Yup.object()
      .shape({
        amount: Yup.number()
          .label('Amount')
          .typeError((options) => `${options.label} is a required field`)
          .required()
          .integer()
          .min(0)
          .max(
            maxBudget,
            `Amount exceeds billing plan. Upgrade your subscription or reduce amount to 
            ${maxBudget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.`
          ),
        cycleDate: Yup.string()
          .label('Monthly start date')
          .when(formFields.type, ([type], schema) => (
            type === TYPE_MONTHLY
              ? schema.required()
              : schema
          )),
        pacingPeriodDuration: Yup.number()
          .nullable()
          .when(formFields.isRepeating, ([isRepeating], schema) => (
            isRepeating
              ? schema
                .label('Pacing period')
                .integer()
                .max(255, 'Pacing period must be less than 256')
                .typeError((options) => `${options.label} is a required field`)
                .required()
                .positive()
              : schema
          )),
        startDate: Yup.date()
          .when('pacingPeriodType', ([pacingPeriodType], schema) => {
            return pacingPeriodType === 'months'
              ? schema.test('is-valid', 'For month type choose a day before 29', (value) => {
                return value && value.getDate() < 29
              })
              : schema
          })
      }),
  },
  {
    id: '3',
    component: StepThree,
    subtitle: 'Campaign selection',
    validationSchema: Yup.object()
      .shape({
        campaigns: Yup.array()
          .label('Campaigns'),
        // .required('Please choose at least one campaign from the list')
        // .min(1),
      }),
  },
  {
    id: '4',
    component: StepFour,
    subtitle: 'KPIs (Optional)',
  },
  {
    id: '5',
    component: StepFive,
    subtitle: 'Automation (Optional)',
    validationSchema: Yup.object()
      .shape({
        rolloverFrom:
          Yup
            .number()
            .nullable(true)
            .when(formFields.budgetRolloverEnabled, ([budgetRolloverEnabled], schema) => (
              budgetRolloverEnabled
                ? schema
                  .label('Rollover')
                  .typeError((options) => `${options.label} is a required field`)
                  .required()
                : schema
            )),
        reduceSpendPercents:
          Yup
            .number()
            .nullable(true)
            .when([formFields.overspendControlEnabled, formFields.overspendControlType], ([overspendControlEnabled, overspendControlType], schema) => (
              overspendControlEnabled && overspendControlType === OVERSPEND_CONTROL_TYPE_REDUCE
                ? schema
                  .label('Reduce spend')
                  .typeError((options) => `${options.label} is a required field`)
                  .required()
                  .min(1)
                  .max(100)
                : schema
            )),
      }),
  },
]

export type BudgetStepType = ReturnType<typeof getSteps>
