import React, { useEffect } from "react"
import clsx from "clsx"
import ReactModal from "react-modal"

// components
import SpriteIcon from "components/ui/SpriteIcon"

// styles
import classes from "components/ui/Modal/Modal.module.scss"

ReactModal.setAppElement("#root")

const sizes = {
  sm: classes.modalSm,
  md: classes.modalMd,
  lg: classes.modalLg,
  xl: classes.modalXl,
  none: "",
}

type propsType = {
  withCloseButton?: boolean
  title?: string
  size?: keyof typeof sizes
  onClose?: () => void
  stretchHeight?: boolean
  className?: string
  closeButtonEnabled?: boolean
  isScrollbarHidden?: boolean
}

const Modal: React.FC<React.PropsWithChildren<propsType>> = ({
  withCloseButton = true,
  title,
  size = "md",
  onClose,
  stretchHeight,
  children,
  className,
  closeButtonEnabled = true,
  isScrollbarHidden = true,
}) => {
  useEffect(() => {
    const documentBody = document.querySelector("body")

    if (!documentBody?.classList.contains("overflow-hidden")) {
      documentBody?.classList.add("overflow-hidden")
    }

    return () => {
      documentBody?.classList.remove("overflow-hidden")
    }
  }, [])

  return (
    <ReactModal
      shouldCloseOnOverlayClick={withCloseButton}
      onRequestClose={onClose}
      className={clsx(
        classes.modal,
        !!isScrollbarHidden && classes.showScrollbar,
        "p-6",
        size && sizes[size],
        stretchHeight && classes.modalStretchHeight,
        className
      )}
      overlayClassName={classes.overlay}
      isOpen
    >
      {withCloseButton && (
        <div
          className="d-flex justify-content-end cursor-pointer color-green"
          onClick={onClose}
          data-cy="close modal"
          role="button"
        >
          {closeButtonEnabled && <SpriteIcon name="close" size="md" />}
        </div>
      )}

      {title && (
        <div className="fs-lg fw-semibold text-center mb-4">{title}</div>
      )}
      {children}
    </ReactModal>
  )
}

export default Modal
