import React from 'react'
import { FilterFunction } from 'react-table'

type FilterType = {
  value?: {
    min: number
  } | string | 'all'
}

type selectFilterMethodType = (filter: FilterType, row: Array<unknown>, options: Array<{ value: string }>, type: string) => boolean

export const selectFilterMethod: FilterFunction | selectFilterMethodType = (
  filter,
  row,
  options,
  type,
) => {
  if (typeof filter.value === 'object' && filter.value.min) {
    return true
  }

  if (filter.value === 'all') {
    return true
  }

  if (type === 'range') {
    const inRange = (value: number, min: number, max: number) => value >= min && value <= max

    const currentOption = options.find((option) => parseInt(option.value) === parseInt(filter.value))
    return inRange(row[filter.id], currentOption?.min, currentOption?.max)
  }

  return filter.value === row[filter.id]
}

const SelectFilter: React.FC<{
  onChange: () => void
  filter: any
  options: Array<{ value: any, label: any } | string>
  type: string
  name: string
}> = ({ onChange, filter, options = [], type = '', name }) => (
  <select
    data-cy={`${name} select`}
    onChange={(e) => onChange(e.target.value)}
    value={filter ? filter.value : 'all'}
    className="form-control">
    <option value="all" data-cy={`${name} option - all`}>All</option>
    {options.map((option, index) => {
      if (type === 'range' && typeof option === 'object' && typeof option.value !== 'undefined' && typeof option.label !== 'undefined') {
        return (
          <option key={index} value={option.value} data-cy={`${name} option - ${option.value}`}>
            {option.label.charAt(0).toUpperCase() + option.label.slice(1)}
          </option>
        )
      }
      if (option) {
        return (
          <option key={index} value={option} data-cy={`${name} option - ${option.value}`}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </option>
        )
      }
    })}
  </select>
)

export default SelectFilter
