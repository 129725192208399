import React from "react"

// constants
import { ACTIVE, PAUSED } from "constants/status"
import Tooltip from "components/ui/Tooltip"

type formDataType = {
  status: string
}

type propsType = {
  original: {
    status: string
    id: string
  }
  updateCampaignStatus: (id: string, formData: formDataType) => void
  disabled: boolean
}

const CellStatusSelect: React.FC<propsType> = ({
  original,
  updateCampaignStatus,
  disabled,
}) => {
  const { status, id } = original

  const handleStatusChange = (val: string) => {
    const formData: formDataType = {
      status: val,
    }

    updateCampaignStatus(id, formData)
  }

  const options = [ACTIVE, PAUSED]

  return (
    <div>
      {disabled && (
        <Tooltip
          content={`The Google Ads API does not allow changes to campaign statuses for video campaigns`}
          id={`table-select-tooltip-${id}`}
        />
      )}

      <select
        onChange={(e) => handleStatusChange(e.target.value)}
        value={status}
        className="form-control"
        disabled={disabled}
        data-tooltip-id={`table-select-tooltip-${id}`}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CellStatusSelect
