import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { _useHubspotChat } from './hs-chat'
import queryString from 'query-string'

import { ENV } from 'constants/main'

// components
import GlobalSpinner from 'components/GlobalSpinner'
import GlobalModal from 'components/ui/GlobalModal'
import DemoSlider from 'pages/Subscriptions/DemoSlider'
import ConfirmEmailVerification from 'pages/ConfirmEmailVerification'

// helpers
import checkIsLoadedInsideIframe from 'helpers/check-is-loaded-inside-iframe'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// accessors
import AuthorizedAccess from 'routes/access/authorized-access'
import UnauthorizedAccess from 'routes/access/unauthorized-access'

import routes from 'routes'
import { useAppDispatch, useAppSelector } from 'state-manager/store'
import { signOutSimple } from 'state-manager/actions/auth'
import { fetchCountries } from 'state-manager/reducers/countries'
import { fetchCurrencies } from 'state-manager/reducers/currencies'

declare global {
  interface Window {
    heap: {
      identify: (id: string) => void
      addUserProperties: (props: Record<string, any>) => void
    }
    hsConversationsOnReady: Array<() => void>
    _hsq?: Array<any>
  }
}

// for cases when something went wrong with heap
if (!window.heap) {
  window.heap = {
    identify: () => {},
    addUserProperties: () => {},
  }
}

const App: React.FC = () => {
  const { firstName, lastName, email } = useAppSelector(
    (state) => state.userData
  )
  const countries = useAppSelector((state) => state.countries.data)
  const currencies = useAppSelector((state) => state.currencies.data)

  const dispatch = useAppDispatch()

  _useDidMount(() => {
    if (!currencies.length) {
      dispatch(fetchCurrencies())
    }
    if (!countries.length) {
      dispatch(fetchCountries())
    }
  })

  const history = useHistory()
  const locationParams = history.location.search
    ? queryString.parse(history.location.search)
    : null

  const isLoadedInsideFrame = checkIsLoadedInsideIframe()

  if (ENV !== 'local' && !isLoadedInsideFrame) {
    _useHubspotChat(8670770, { email, firstName, lastName })
  }

  // shows subscription form outside dashboard
  if (isLoadedInsideFrame) {
    return <DemoSlider />
  }

  useEffect(() => {
    if (locationParams?.logout) {
      dispatch(signOutSimple())
      history.replace(routes.modalSignUp[0])
    }
  }, [dispatch, history, locationParams?.logout])

  if (locationParams?.logout) {
    return null
  }

  return (
    <>
      <ToastContainer hideProgressBar />

      <GlobalSpinner />

      <GlobalModal />

      <Switch>
        <Route
          exact
          path={routes.modalVerification}
          component={ConfirmEmailVerification}
        />
        <AuthorizedAccess path={routes.panel} />

        <UnauthorizedAccess />
      </Switch>
    </>
  )
}

export default App
